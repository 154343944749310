import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import store, { persistor } from './store'
import Theme from './components/template/Theme'
import Layout from './components/layout'
import mockServer from './mock'
import appConfig from './configs/app.config'
import './locales'
import DecoratedApolloProvider from './services'

const environment = process.env.NODE_ENV

/**
 * Set enableMock(Default false) to true at configs/app.config.js
 * If you wish to enable mock api
 */

if (environment !== 'production' && appConfig.enableMock) {
  mockServer({ environment })
}
function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter basename={'vc-admin'}>
          <DecoratedApolloProvider>
            <Theme>
              <Layout />
            </Theme>
          </DecoratedApolloProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  )
}
export default App
