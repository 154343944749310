import React, { CSSProperties } from 'react'
import classNames from 'classnames'

type BadgeProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> & {
  className?: string
  innerClass?: string
  children?: React.ReactNode
  content?: string | number
  maxCount?: number
  badgeStyle?: CSSProperties
}

const defaultMaxCount = 99

const Badge = React.forwardRef<HTMLSpanElement, BadgeProps>(function Badge(props, ref) {
  const { content, maxCount = defaultMaxCount, className, innerClass, children, badgeStyle, ...rest } = props

  const dot = typeof content !== 'number' && typeof content !== 'string'

  const badgeClass = classNames(dot ? 'badge-dot' : 'badge', innerClass)

  const renderBadge = () => {
    if (children) {
      return (
        <span className={classNames('badge-wrapper', className)} ref={ref} {...rest}>
          <span className={classNames(badgeClass, 'badge-inner')} style={badgeStyle}>
            {typeof content === 'number' && content > maxCount ? `${maxCount}+` : content}
          </span>
          {children}
        </span>
      )
    }
    return (
      <span className={classNames(badgeClass, className)} ref={ref} style={badgeStyle} {...rest}>
        {content}
      </span>
    )
  }

  return renderBadge()
})

export default Badge
