import React from 'react'
import { Button } from '../../ui'

const DrawerFooter = ({
  onSaveClick,
  onCancel,
  loading = false,
}: {
  onSaveClick: () => void
  onCancel: () => void
  loading?: boolean
}) => {
  return (
    <div className="flex flex-row-reverse text-right w-full">
      <Button loading={loading} size="sm" variant="solid" onClick={onSaveClick}>
        Save
      </Button>
      <Button size="sm" className="mr-2" onClick={onCancel}>
        Cancel
      </Button>
    </div>
  )
}

export default DrawerFooter
