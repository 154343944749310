import { GateType, WorkflowStatus } from '../../../../graphql'
import { truncate } from 'lodash'
import React from 'react'
import { Badge, Spinner, Tag, Tooltip } from '../../../ui'
import { HiOutlinePencil, HiOutlineTrash } from 'react-icons/hi'
import useThemeClass from '../../../../utils/hooks/useThemeClass'

export const CategoriesCell = ({ categories }: { categories: { name: string }[] }) => {
  return (
    <div className="flex flex-col">
      {categories.map((c, i) => (
        <span style={{ whiteSpace: 'nowrap' }} key={i}>
          {truncate(c.name, { length: 20 })}
        </span>
      ))}
    </div>
  )
}

export const DateCell = ({ start, end }: { start?: Date; end?: Date }) => {
  const startStr = start && new Date(start).toLocaleString('en-US', { dateStyle: 'medium' })
  const endStr = end && new Date(end).toLocaleString('en-US', { dateStyle: 'medium' })
  const dateStr =
    (startStr ? startStr : '') + (endStr && startStr ? ` - ${endStr}` : endStr ? `Expire on ${endStr}` : '')
  return <span style={{ whiteSpace: 'nowrap' }}>{dateStr}</span>
}

export const GateCell = ({
  gate,
}: {
  gate?: {
    type: GateType
  } | null
}) => {
  if (!gate) return null
  let color = 'bg-gray-500'
  let name = ''
  switch (gate.type) {
    case 'registration':
      color = 'bg-pink-500'
      name = 'Registration'
      break
    case 'ppv':
      color = 'bg-green-500'
      name = 'Pay-Per-View'
      break
    case 'subscription':
      color = 'bg-blue-500'
      name = 'Subscription'
      break
  }
  return (
    <div className="flex items-center gap-2">
      <Badge innerClass={color} />
      {gate && <span style={{ whiteSpace: 'nowrap' }}>{name}</span>}
    </div>
  )
}

export const NameCell = ({
  imageSrc,
  name,
  workflowStatus,
  aspectVideo = true,
}: {
  imageSrc?: string
  name: string
  workflowStatus?: WorkflowStatus
  aspectVideo?: boolean
}) => {
  let workflowStatusPill = null
  const pillStyles = {
    padding: '0px 10px',
    marginLeft: 8,
    borderRadius: 10,
  }
  switch (workflowStatus) {
    case WorkflowStatus.Processing:
      workflowStatusPill = (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <Tooltip title={'Hang tight as we process your video'}>
          <span style={pillStyles} className={'cursor-pointer'}>
            <Tag
              className={'text-amber-500 border-amber-500 dark:border-amber-500'}
              prefix={<Spinner size={13} color={'amber-500'} className={'mr-1.5 rtl:ml-1'} />}>
              Processing
            </Tag>
          </span>
        </Tooltip>
      )
      break
    case WorkflowStatus.Error:
      workflowStatusPill = (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <Tooltip title={'Something went wrong with processing the video. Please try adding a new video source file'}>
          <span style={pillStyles} className={'cursor-pointer'}>
            <Tag className={'text-red-500 border-red-500 dark:border-red-500'} prefix prefixClass={'bg-red-500'}>
              Failed
            </Tag>
          </span>
        </Tooltip>
      )
      break
    case WorkflowStatus.None:
      workflowStatusPill = (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <Tooltip title={'Waiting for a video source file to be uploaded before processing'}>
          <span style={pillStyles} className={'cursor-pointer'}>
            <Tag prefix>Waiting</Tag>
          </span>
        </Tooltip>
      )
      break
  }
  return (
    <div className="flex items-center">
      {imageSrc && <img className={`mr-4 h-8 ${aspectVideo ? 'aspect-video' : ''} rounded`} src={imageSrc} alt={''} />}
      <span style={{ whiteSpace: 'nowrap' }}>{truncate(name, { length: 20 })}</span>
      {workflowStatusPill}
    </div>
  )
}

export function ActionCell<D>({
  row,
  onEdit,
  onDelete,
}: {
  row: D
  onEdit?: (row: D) => void
  onDelete?: ((row: D) => void) | null
}) {
  const { textTheme } = useThemeClass()

  return (
    <div className="flex justify-end text-lg">
      <span className={`cursor-pointer p-2 hover:${textTheme}`} onClick={() => onEdit?.(row)}>
        <HiOutlinePencil />
      </span>
      <span
        className={`p-2 ${onDelete ? 'cursor-pointer hover:text-red-500' : 'opacity-0'}`}
        onClick={() => onDelete?.(row)}>
        <HiOutlineTrash />
      </span>
    </div>
  )
}
