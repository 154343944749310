// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck
import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import THead from './THead'
import TBody from './TBody'
import TFoot from './TFoot'
import Tr from './Tr'
import Th from './Th'
import Td from './Td'
import Sorter from './Sorter'

const Table = React.forwardRef((props, ref) => {
  const {
    borderlessRow,
    children,
    className,
    hoverable,
    compact,
    oveerflow = true,
    asElement: Component,
    ...rest
  } = props

  const tableClass = classNames(
    Component === 'table' ? 'table-default' : 'table-flex',
    hoverable && 'table-hover',
    compact && 'table-compact',
    borderlessRow && 'borderless-row',
    className,
  )

  return (
    <div className={classNames(oveerflow && 'overflow-x-auto')}>
      <Component className={tableClass} {...rest} ref={ref}>
        {children}
      </Component>
    </div>
  )
})

Table.propTypes = {
  hoverable: PropTypes.bool,
  compact: PropTypes.bool,
  asElement: PropTypes.string,
  borderlessRow: PropTypes.bool,
}

Table.defaultProps = {
  hoverable: true,
  compact: false,
  asElement: 'table',
  borderlessRow: false,
}

Table.displayName = 'Table'
export default Table as typeof Table & {
  THead: typeof THead
  TBody: typeof TBody
  TFoot: typeof TFoot
  Tr: typeof Tr
  Th: typeof Th
  Td: typeof Td
  Sorter: typeof Sorter
}
