import React from 'react'
import classNames from 'classnames'
import { useConfig } from '../ConfigProvider'

type CardProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  clickable?: boolean
  bordered?: boolean
  bodyClass?: string
  header?: React.ReactNode | string
  headerClass?: string
  footer?: React.ReactNode | string
  footerClass?: string
  headerBorder?: boolean
  footerBorder?: boolean
  headerExtra?: React.ReactNode
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void
}

const Card = React.forwardRef<HTMLDivElement, CardProps>(function Card(props, ref) {
  const { cardBordered } = useConfig()

  const {
    children,
    className,
    clickable = false,
    onClick,
    bordered = cardBordered || false,
    bodyClass,
    header,
    headerClass,
    headerBorder = false,
    headerExtra,
    footer,
    footerClass,
    footerBorder = false,
    ...rest
  } = props

  const cardClass = classNames(
    'card',
    className,
    bordered ? 'card-border' : 'card-shadow',
    clickable && 'cursor-pointer user-select-none',
  )

  const cardBodyClasss = classNames('card-body', bodyClass)
  const cardHeaderClass = classNames(
    'card-header',
    headerBorder && 'card-header-border',
    headerExtra && 'card-header-extra',
    headerClass,
  )
  const cardFooterClass = classNames('card-footer', footerBorder && 'card-footer-border', footerClass)

  const renderHeader = () => {
    if (typeof header === 'string') {
      return <h4>{header}</h4>
    }
    return <>{header}</>
  }

  const onCardClick = (e: React.MouseEvent<HTMLDivElement>) => {
    onClick?.(e)
  }

  return (
    <div className={cardClass} ref={ref} {...rest} onClick={onCardClick}>
      {header && (
        <div className={cardHeaderClass}>
          {renderHeader()}
          {headerExtra && <span>{headerExtra}</span>}
        </div>
      )}
      <div className={cardBodyClasss}>{children}</div>
      {footer && <div className={cardFooterClass}>{footer}</div>}
    </div>
  )
})

export default Card
