import { combineReducers } from 'redux'
import theme from './theme/themeSlice'
import auth from './auth'
import base from './base'
import data from './data'
import video from './video'
import upload from './upload/uploadSlice'

const rootReducer = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    theme,
    auth,
    base,
    data,
    video,
    upload,
    ...asyncReducers,
  })
  return combinedReducer(state, action)
}

export default rootReducer
