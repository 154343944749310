import React from 'react'
import ConfirmDialog from './ConfirmDialog'

type DeleteDialogProps = {
  data: { id: string; name?: string; type?: string; originId?: string | null }[] | null
  loading: boolean
  onCancel: () => void
  onConfirm: () => void
}

const DeleteDialog = ({ data, loading, onCancel, onConfirm }: DeleteDialogProps) => {
  let dataType = ''
  let containsExternalContent = false

  if (data && data.length) {
    dataType = data[0]?.type ? data[0].type : ''
    containsExternalContent = !!(data && data.find((d) => d.originId))
  }

  let message =
    data?.length === 1
      ? `Are you sure you want to delete ${data[0]?.name}? This action cannot be undone.`
      : containsExternalContent
      ? `Some of the selected ${dataType}s originate from an integration. They are unable to be deleted.`
      : `Are you sure you want to delete these ${data?.length} ${
          dataType === 'category' ? 'categorie' : dataType
        }s? This action cannot be undone.`

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <ConfirmDialog
      isOpen={!!data}
      onClose={onCancel}
      onRequestClose={onCancel}
      type="danger"
      title="Delete"
      onCancel={onCancel}
      onConfirm={onConfirm}
      confirmButtonColor="red-600"
      disabled={containsExternalContent}
      loading={loading}>
      <p>{message}</p>
    </ConfirmDialog>
  )
}

export default DeleteDialog
