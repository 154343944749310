import React from 'react'
import classNames from 'classnames'
import { useConfig } from '../ConfigProvider'
import { CgSpinner } from 'react-icons/cg'
import { IconType, IconBaseProps } from 'react-icons'

type SpinnerProps = IconBaseProps & {
  className?: string
  color?: string
  enableTheme?: boolean
  indicator?: React.ForwardRefExoticComponent<IconBaseProps & React.RefAttributes<IconType>>
  isSpining?: boolean
  size?: number | string
  style?: React.CSSProperties
}

const Spinner = React.forwardRef<IconType, SpinnerProps>(function Spinner(props, ref) {
  const { className, color, enableTheme = true, isSpining = true, size = 20, style, ...rest } = props

  const Component = rest.indicator || CgSpinner

  const { themeColor, primaryColorLevel } = useConfig()

  const spinnerColor = color || (enableTheme && `${themeColor}-${primaryColorLevel}`)

  const spinnerStyle = {
    height: size,
    width: size,
    ...style,
  }

  const spinnerClass = classNames(isSpining && 'animate-spin', spinnerColor && `text-${spinnerColor}`, className)

  return <Component ref={ref} style={spinnerStyle} className={spinnerClass} {...rest} />
})

export default Spinner
