import React from 'react'
import { AsyncPaginate } from 'react-select-async-paginate'
import { AdaptableCard, DataTable } from '../../../components/shared'
import { truncate } from 'lodash'
import { EntryStatus, GateType, useCategoriesLazyQuery, useProductsLazyQuery, WorkflowStatus } from '../../../graphql'
import { Filters } from '../Filter'
import { DefaultAdditional } from '../Filter'
import { CellProps } from './DataTable'
import { DataTableColumn } from './DataTable'
import { GateCell, NameCell, DateCell, ActionCell } from './components/cells'
import { TableTools } from './components'
import { Maybe } from 'yup/es/types'
import { DropResult } from 'react-beautiful-dnd'
import { TableFilter, TableOption } from '../../../store/data/tableSlice'

export type EntryTableData = {
  name: string
  id: string
  publishOn?: Date
  expireOn?: Date
  description?: string | null
  type?: string | null
  originId?: string | null
  gate?: {
    id: string
    type: GateType
    product?: {
      name: string
    } | null
  } | null
  appliedGate?: {
    id: string
    type: GateType
    product?: {
      name: string
    } | null
  } | null
  status: EntryStatus
  workflowStatus?: WorkflowStatus
}

export type DefaultColumns<T extends EntryTableData> = {
  name: DataTableColumn<T>
  publish: DataTableColumn<T>
  description: DataTableColumn<T>
  gate: DataTableColumn<T>
  product: DataTableColumn<T>
  actions: DataTableColumn<T>
}

export type DefaultFilters = {
  category: TableFilter<string, FilterOption>
  gate: TableFilter<GateType, GateFilterOption>
  product: TableFilter<string, FilterOption>
}

export type GateFilterOption<V = GateType, L = string> = FilterOption<V, L> & {
  color?: string
}

export type FilterOption<V = string, L = string> = TableOption<V> & {
  label: L
  value: V
}

export type EntryFilters = {
  category: FilterOption[]
  gate: (FilterOption<GateType> & { color: string })[]
  product: FilterOption[]
}

type EntryTableProps<D extends EntryTableData> = {
  data?: {
    total: number
    list?: Array<Maybe<D>>
    hasMore: boolean
  }
  name: string
  loading?: boolean
  columns?: ((entryColumns: DefaultColumns<D>) => DataTableColumn<D>[]) | DataTableColumn<D>[]
  filters?:
    | ((entryFilters: Filters<DefaultFilters, DefaultAdditional>) => Filters<DefaultFilters, DefaultAdditional>)
    | Filters<DefaultFilters, DefaultAdditional>
  onSelectChange?: (pageSize: number) => void
  onPaginationChange?: (pageIndex: number) => void
  onSearchInputChange?: (value: string) => void
  onCheckBoxChange?: (checked: boolean, row: D) => void
  onIndeterminateCheckBoxChange?: (checked: boolean, rows: D[]) => void
  draggable?: boolean
  sortable?: boolean
  onDragEnd?: (result: DropResult & { newData: D[] }) => void
  selectable?: boolean
  onEdit?: (row: D) => void
  onDelete?: (row: D) => void
}

function EntryTable<D extends EntryTableData>({
  data: { total, list } = { total: 0, hasMore: false },
  loading = false,
  columns: incomingColumns = (c) => Object.keys(c).map((k) => c[k as keyof DefaultColumns<D>]),
  filters: incomingFilters = (f) => f as Filters<DefaultFilters, DefaultAdditional>,
  name: tableName,
  onSearchInputChange,
  selectable = true,
  onEdit,
  onDelete,
  ...rest
}: EntryTableProps<D>) {
  const [fetchCategories] = useCategoriesLazyQuery()
  const [fetchProducts] = useProductsLazyQuery()

  const defaultColumns: DefaultColumns<D> = {
    name: {
      header: 'Name',
      accessorKey: 'name',
      sortable: false,
      // eslint-disable-next-line @typescript-eslint/no-shadow
      cell: ({
        row: {
          original: { name, sixteenNineCover, workflowStatus },
        },
      }: CellProps<D & { sixteenNineCover?: { src?: string } }>) => {
        return <NameCell imageSrc={sixteenNineCover?.src} name={name} workflowStatus={workflowStatus} />
      },
    },
    publish: {
      header: 'Publish',
      sortable: false,
      cell: ({
        row: {
          original: { publishOn, expireOn },
        },
      }: CellProps<D>) => {
        return <DateCell start={publishOn} end={expireOn} />
      },
    },
    description: {
      header: 'Description',
      accessorKey: 'description',
      sortable: false,
    },
    gate: {
      header: 'Gate',
      accessorKey: 'gate',
      sortable: false,
      // eslint-disable-next-line @typescript-eslint/no-shadow
      cell: (props: CellProps<D>) => {
        const row = props.row.original
        const gate = row.appliedGate || row.gate
        return <GateCell gate={gate} />
      },
    },
    product: {
      header: 'Product',
      sortable: false,
      cell: ({ row: { original } }: CellProps<D>) => {
        const gate = original.appliedGate || original.gate
        return <span style={{ whiteSpace: 'nowrap' }}>{truncate(gate?.product?.name, { length: 20 })}</span>
      },
    },
    actions: {
      header: '',
      accessorKey: 'actions',
      sortable: false,
      cell: ({ row }: CellProps<D>) => (
        <ActionCell
          row={row.original}
          onEdit={onEdit}
          onDelete={row.original?.type === 'video' && row.original?.originId ? null : onDelete}
        />
      ),
    },
  }

  let columns: DataTableColumn<D & object>[]
  if (typeof incomingColumns === 'function') {
    columns = incomingColumns(defaultColumns)
  } else {
    columns = incomingColumns
  }

  const defaultFilters: Filters<DefaultFilters, DefaultAdditional> = {
    category: {
      isMulti: true,
      placeholder: 'Category',
      componentAs: AsyncPaginate,
      defaultOptions: true,
      loadOptions: async (inputValue, _loadedOptions, additional) => {
        const page = additional?.page || 0
        const result = await fetchCategories({
          variables: {
            data: {
              search: inputValue,
              take: 30,
              skip: page * 30,
            },
          },
        })

        const options =
          result.data?.categories?.list.map((c) => ({
            label: c.name,
            value: c.id,
          })) || []

        return {
          options,
          hasMore: !!result?.data?.categories?.hasMore,
          additional: {
            page: page + 1,
          },
        }
      },
    },
    gate: {
      isMulti: true,
      placeholder: 'Gate',
      type: 'color',
      options: [
        { value: GateType.Registration, label: 'Registration', color: 'bg-pink-500' },
        { value: GateType.Ppv, label: 'Pay-Per-View', color: 'bg-green-500' },
        { value: GateType.Subscription, label: 'Subscription', color: 'bg-blue-500' },
      ],
    },
    product: {
      isMulti: true,
      placeholder: 'Product',
      componentAs: AsyncPaginate,
      defaultOptions: true,
      loadOptions: async (inputValue: string) => {
        // Todo: implement pagination someday
        // Note that since stripe does not return a total with paginated results pagination doesn't make a lot of sense on our product table.
        // However pagination could be used here to make our filter faster since it does not care about a total
        const result = await fetchProducts({
          variables: {
            data: {
              search: inputValue,
            },
          },
        })

        const options =
          result.data?.products?.map((p) => ({
            label: p.name,
            value: p.id,
          })) || []

        return { options }
      },
    },
  }

  let filters: Filters<DefaultFilters, DefaultAdditional>

  if (typeof incomingFilters === 'function') {
    filters = incomingFilters(defaultFilters)
  } else {
    filters = incomingFilters
  }

  return (
    <AdaptableCard className="h-full" bodyClass="h-full">
      <TableTools tableName={tableName} filters={filters} onSearchInputChange={onSearchInputChange} search />
      <DataTable
        selectable={selectable}
        name={tableName}
        columns={columns as DataTableColumn<D>[]}
        data={list as D[]}
        loading={loading}
        total={total}
        skeletonAvatarProps={{ width: 40, height: 40 }}
        skeletonAvatarColumns={[0]}
        {...rest}
      />
    </AdaptableCard>
  )
}

export default EntryTable
