import React from 'react'
import { Badge } from '../index'

const CardField = ({ title, value, color }: { title?: string; value: string; color?: string }) => (
  <div>
    <span>{title}</span>
    <div className={'flex items-center gap-2'}>
      {color && <Badge innerClass={color} />}
      <p className="text-gray-700 dark:text-gray-200 font-semibold">{value}</p>
    </div>
  </div>
)

export default CardField
