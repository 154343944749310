import { ApolloClient, InMemoryCache } from '@apollo/client'
import decorateLink from './LinkService'
import { DecoratedClientProps } from './index'

const cache = new InMemoryCache()

const decorateClient = (props: DecoratedClientProps) =>
  new ApolloClient({
    link: decorateLink(props),
    cache,
  })

export default decorateClient
