import React from 'react'
import { ApolloProvider, ApolloClient, NormalizedCacheObject } from '@apollo/client'
import decorateClient from './ApolloService'
import useAuth from '../utils/hooks/useAuth'

// Add additional decorative props as needed here
export type DecoratedClientProps = {
  auth: {
    signOut: () => void
    session: {
      token?: string
    }
  }
}

export let client: ApolloClient<NormalizedCacheObject> | null = null

function DecoratedApolloProvider({
  children,
  ...rest
}: Omit<DecoratedClientProps, 'auth'> & { children: React.ReactNode }) {
  const { signOut, token } = useAuth()

  client = decorateClient({
    auth: {
      signOut,
      session: {
        token: token,
      },
    },
    ...rest,
  })
  return <ApolloProvider client={client}>{children}</ApolloProvider>
}

export async function handleErrors<T>(promise?: Promise<T>) {
  if (!promise) throw new Error('Client is not initialized')
  try {
    return await promise
  } catch (error) {
    if (error instanceof Array && error.length > 0) {
      const firstError = error[0]
      if (firstError.message) {
        throw new Error(firstError.message, {
          cause: error,
        })
      }
    }
    if (error instanceof Error) {
      if (error.message) {
        throw new Error(error.message, {
          cause: error,
        })
      }
    }
    throw new Error('Something went wrong', {
      cause: error,
    })
  }
}

export default DecoratedApolloProvider
