import React, { forwardRef, JSXElementConstructor } from 'react'
import classNames from 'classnames'

type ComponentProps = React.ComponentProps<JSXElementConstructor<unknown>> & {
  className?: string
  children?: React.ReactNode
  ref?: React.Ref<unknown>
}

type ContainerProps = Omit<React.ComponentProps<JSXElementConstructor<unknown>>, 'asElement'> & {
  className?: string
  children?: React.ReactNode
  asElement?: typeof React.Component<ComponentProps> | string
}

const Container = forwardRef(function Container(props: ContainerProps, ref) {
  const { className, children, asElement: Component = 'div', ...rest } = props

  return (
    <Component ref={ref} className={classNames('container mx-auto', className)} {...rest}>
      {children}
    </Component>
  )
})

export default Container
