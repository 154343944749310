import React from 'react'
import DefaultFilter from './components/DefaultFilter'
import ColorFilter from './components/ColorFilter'
import { SelectProps } from '../../ui'
import { TableFilters as TableFiltersT, FilterOption } from '../../../store/data/tableSlice'

export type Filters<F extends TableFiltersT, Additional, FormValues = unknown> = {
  [P in keyof F]?: FilterProps<FilterOption<F[P]>, Additional, FormValues>
}

export type FilterProps<Option, Additional, FormValues> = SelectProps<Option, Additional, FormValues> & {
  type?: 'color' | 'default'
}

export type DefaultOption = {
  label: string
  value: string
}

export type DefaultAdditional = {
  page: number
}

const Filter: <Option, Additional, FormValues>(props: FilterProps<Option, Additional, FormValues>) => JSX.Element = ({
  type = 'default',
  ...props
}) => {
  return type === 'color' ? <ColorFilter {...props} /> : <DefaultFilter {...props} />
}

export default Filter
