import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Filter from '../../Filter'
import { setFilterValue, selectTable, TableRow } from '../../../../store/data/tableSlice'
import { ActionMeta, PropsValue } from 'react-select'
import { Filters } from '../../Filter'
import { TableFilters as TableFiltersT, FilterOption } from '../../../../store/data/tableSlice'

type TableFiltersProps<F extends TableFiltersT, FilterAdditional> = {
  tableName: string
  filters?: Filters<F, FilterAdditional>
}

function TableFilters<Row extends TableRow, F extends TableFiltersT, FilterAdditional>({
  tableName,
  filters = {} as Filters<F, FilterAdditional>,
}: TableFiltersProps<F, FilterAdditional>) {
  const filterValues = useSelector(selectTable<Row, F>(tableName))?.filters

  const dispatch = useDispatch()

  const filterComponents: JSX.Element[] = []
  Object.keys(filters).forEach((key: keyof Filters<F, FilterAdditional>) => {
    const props = filters[key]
    if (props) {
      const value: PropsValue<FilterOption<F[keyof F]>> | null =
        (filterValues?.[key] as FilterOption<F[keyof F]> | FilterOption<F[keyof F]>[]) || null

      const onChange = (
        newValue: PropsValue<FilterOption<F[keyof F]>>,
        actionMeta: ActionMeta<FilterOption<F[keyof F]>>,
      ) => {
        dispatch(
          setFilterValue({
            key: tableName,
            filterKey: key.toString(),
            newValue: newValue as FilterOption<F[keyof F]> | FilterOption<F[keyof F]>[] | null,
          }),
        )
        if (props?.onChange) {
          props.onChange(value, actionMeta)
        }
      }

      filterComponents.push(
        <Filter {...props} value={value} key={tableName + ':' + key.toString()} onChange={onChange} />,
      )
    }
  })

  return <>{filterComponents.map((filter) => filter)}</>
}

export default TableFilters
