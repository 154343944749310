import React, { ForwardedRef, forwardRef } from 'react'
import { Button } from '../index'
import classNames from 'classnames'
import { Action } from '../../shared'

type TabListProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  actions?: Action[]
  variant?: string
}

const TabList = forwardRef(function TabList(props: TabListProps, ref: ForwardedRef<HTMLDivElement>) {
  const { className, children, variant = 'underline', actions, ...rest } = props

  const tabListClass = classNames('tab-list', `tab-list-${variant}`, className)

  return (
    <div
      role="tablist"
      className={
        actions?.length
          ? 'items-center md:flex  overflow-y-hidden tab-list-underline justify-between gap-4' + className
          : tabListClass
      }
      ref={ref}
      {...rest}>
      {actions?.length ? (
        <>
          <div className={'md:mb-0 mb-4  tab-list md:border-b-0 tab-list-underline'}>{children}</div>
          <div className="md:mb-2 mb-4 pr-4 flex md:flex-row-reverse flex-col gap-4">
            {actions?.map((action, index) => {
              const {
                size = 'sm',
                Component = Button,
                title,
                // eslint-disable-next-line @typescript-eslint/no-shadow
                ...rest
              } = action
              return (
                <Component key={index} size={size} {...rest}>
                  {title}
                  {rest.children}
                </Component>
              )
            })}
          </div>
        </>
      ) : (
        children
      )}
    </div>
  )
})

export default TabList
