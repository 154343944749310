import React from 'react'
import { Card, CardField, FormItem } from '../ui'
import { Catalog, Category, GateType, VideoFieldsFragment } from '../../graphql'
import { FormikValues } from 'formik'

type AppliedGateCardProps = {
  entry?: VideoFieldsFragment | Category | Catalog
  values: FormikValues
}

const gates = [
  { label: 'None', value: null },
  { value: GateType.Registration, label: 'Registration', color: 'bg-pink-500' },
  { value: GateType.Ppv, label: 'Pay-Per-View', color: 'bg-green-500' },
  { value: GateType.Subscription, label: 'Subscription', color: 'bg-blue-500' },
]

const AppliedGateCard = ({ entry, values }: AppliedGateCardProps) => {
  let appliedGate = entry?.appliedGate && { type: entry.appliedGate.type, product: entry.appliedGate.product?.name }
  if (values.gateEnabled) {
    appliedGate = values.gate.value && {
      type: values.gate.value,
      product: values.product?.label,
    }
  } else {
    appliedGate = entry?.inheritedGate && {
      type: entry.inheritedGate.type,
      product: entry.inheritedGate.product?.name,
    }
  }
  if (!appliedGate) return null
  const gate = gates.find((g) => g.value === appliedGate?.type)
  return gate ? (
    <FormItem>
      <Card>
        <div className="flex flex-col mx-auto">
          <div className="grid grid-cols-1 gap-y-4 gap-x-4">
            <CardField value={gate.label} color={gate.color} />
            {appliedGate.product && <CardField value={appliedGate.product} />}
          </div>
        </div>
      </Card>
    </FormItem>
  ) : null
}

export default AppliedGateCard
