import React, { forwardRef, ForwardedRef } from 'react'
import { TabsContextProvider } from './context'
import useControllableState from '../hooks/useControllableState'
import classNames from 'classnames'
import TabList from './TabList'
import TabNav from './TabNav'
import TabContent from './TabContent'

export type TabsProps<Value> = Omit<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  'defaultValue' | 'value' | 'onChange' | 'variant'
> & {
  variant?: 'underline' | 'pill'
  defaultValue?: Value
  value?: Value
  onChange?: (val: Value) => void
}

const Tabs = forwardRef(function Tabs<Value>(props: TabsProps<Value>, ref: ForwardedRef<HTMLDivElement>) {
  const { value: valueProp, onChange, defaultValue, variant = 'underline', className, ...rest } = props

  const [value, setValue] = useControllableState({
    prop: valueProp,
    onChange,
    defaultProp: defaultValue,
  })

  const tabsClass = classNames('tabs', className)

  return (
    <TabsContextProvider
      value={{
        value: value,
        onValueChange: setValue,
        variant,
      }}>
      <div className={tabsClass} {...rest} ref={ref} />
    </TabsContextProvider>
  )
})

export default Tabs as (<Value>(props: TabsProps<Value> & { ref?: ForwardedRef<HTMLDivElement> }) => JSX.Element) & {
  TabList: typeof TabList
  TabNav: typeof TabNav
  TabContent: typeof TabContent
}
