import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
  drawerOpen: false,
  selectedVideo: {},
}

const videoSlice = createSlice({
  name: 'video/state',
  initialState,
  reducers: {
    setSelectedVideo: (state, action) => {
      state.selectedVideo = action.payload
    },
    setDrawerOpen: (state) => {
      state.drawerOpen = true
    },
    setDrawerClose: (state) => {
      state.drawerOpen = false
    },
  },
})

export const { setSelectedVideo, setDrawerOpen, setDrawerClose } = videoSlice.actions

export default videoSlice.reducer
