/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { forwardRef } from 'react'
import { Input } from '../../../ui'
import { HiOutlineSearch } from 'react-icons/hi'
import debounce from 'lodash/debounce'
import { useDispatch } from 'react-redux'
import { setSearch } from '../../../../store/data/tableSlice'

type TableSearchProps = {
  onInputChange?: (value: string) => void
  tableName?: string
}

const TableSearch = forwardRef<HTMLInputElement, TableSearchProps>(function TableSearch(props, ref) {
  const { onInputChange, tableName } = props

  const dispatch = useDispatch()

  const debounceFn = debounce(handleDebounceFn, 500)

  function handleDebounceFn(val: string) {
    if (tableName) {
      dispatch(setSearch({ key: tableName, search: val }))
    }
    onInputChange?.(val)
  }

  const handleInputChange = (e: { target: { value: string } }) => {
    debounceFn(e.target.value)
  }

  return (
    <Input
      ref={ref}
      // @ts-ignore
      className="max-w-md md:w-52 mb-4"
      size="sm"
      placeholder="Search"
      prefix={<HiOutlineSearch className="text-lg" />}
      onChange={handleInputChange}
    />
  )
})

export default TableSearch
