import React from 'react'
import { components, MultiValueProps, SingleValueProps, OptionProps, GroupBase } from 'react-select'
import { Select, Badge } from '../../../../components/ui'
import { HiCheck } from 'react-icons/hi'

const CustomMultiValue: <Option extends { color?: string }>(
  props: MultiValueProps<Option, boolean, GroupBase<Option>>,
) => JSX.Element = ({ children, ...props }) => {
  const { data } = props
  return (
    <components.MultiValue {...props}>
      {data.color && (
        <Badge style={{ marginLeft: 3, marginRight: 7, display: 'inline-block' }} innerClass={data.color} />
      )}
      {children}
    </components.MultiValue>
  )
}

const CustomSingleValue: <Option extends { color?: string }>(
  props: SingleValueProps<Option, boolean, GroupBase<Option>>,
) => JSX.Element = ({ children, ...props }) => {
  const { data } = props
  return (
    <components.SingleValue {...props}>
      {data.color && (
        <Badge style={{ marginLeft: 3, marginRight: 7, display: 'inline-block' }} innerClass={data.color} />
      )}
      {children}
    </components.SingleValue>
  )
}

const CustomSelectOption: <Option extends { color?: string }>(
  props: OptionProps<Option, boolean, GroupBase<Option>>,
) => JSX.Element = ({ innerProps, label, data, isSelected }) => {
  return (
    <div
      className={`flex items-center justify-between p-2 cursor-pointer ${
        isSelected ? 'bg-gray-100 dark:bg-gray-500' : 'hover:bg-gray-50 dark:hover:bg-gray-600'
      }`}
      {...innerProps}>
      <div className="flex items-center gap-2">
        {data.color ? <Badge innerClass={data.color.toString()} /> : null}
        <span>{label}</span>
      </div>
      {isSelected && <HiCheck className="text-emerald-500 text-xl" />}
    </div>
  )
}

function ColorFilter<Option extends { color: string }>({
  size = 'sm',
  className = 'mb-4 min-w-[130px]',
  isMulti = true,
  ...props
}) {
  //todo: add support for single value color filters, for now hardcoded as isMulti
  return (
    <Select
      isMulti={isMulti}
      size={size as 'md' | 'sm' | 'lg' | undefined}
      className={className}
      {...props}
      components={{
        ...props?.components,
        Option: CustomSelectOption as (props: OptionProps<Option, boolean, GroupBase<Option>>) => JSX.Element,
        MultiValue: CustomMultiValue as (props: MultiValueProps<Option, boolean, GroupBase<Option>>) => JSX.Element,
        SingleValue: CustomSingleValue,
      }}
    />
  )
}

export default ColorFilter
