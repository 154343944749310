import Tabs, { TabsProps } from './Tabs'
import TabList from './TabList'
import TabNav from './TabNav'
import TabContent from './TabContent'

Tabs.TabList = TabList
Tabs.TabNav = TabNav
Tabs.TabContent = TabContent

export { type TabsProps }

export default Tabs as typeof Tabs & {
  TabList: typeof TabList
  TabNav: typeof TabNav
  TabContent: typeof TabContent
}
