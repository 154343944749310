import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  DateTime: any
}

export type AwsCredentials = {
  __typename?: 'AWSCredentials'
  accessKeyId: Scalars['String']
  resourceName: Scalars['String']
  secretAccessKey: Scalars['String']
  sessionToken: Scalars['String']
}

export type AwsCredentialsInput = {
  type: AwsCredentialsType
}

export enum AwsCredentialsType {
  UploadAsset = 'upload_asset',
  UploadSource = 'upload_source',
}

export type Api = {
  __typename?: 'Api'
  clientId?: Maybe<Scalars['String']>
  clientSecret?: Maybe<Scalars['String']>
  type: ApiType
}

export type ApiInput = {
  clientId?: InputMaybe<Scalars['String']>
  clientSecret?: InputMaybe<Scalars['String']>
  type: ApiType
}

export enum ApiType {
  Notion = 'notion',
  Postgresql = 'postgresql',
}

export type AuthorizeIntegrationDetailInput = {
  code: Scalars['String']
  id: Scalars['String']
}

export type Catalog = Entry &
  Record & {
    __typename?: 'Catalog'
    appliedGate?: Maybe<Gate>
    /** Describes how to filter the content for the catalog. */
    contentFilter?: Maybe<FilterGroup>
    createdAt: Scalars['DateTime']
    description?: Maybe<Scalars['String']>
    downloadsEnabled: Scalars['Boolean']
    enabled: Scalars['Boolean']
    expireOn?: Maybe<Scalars['DateTime']>
    gate?: Maybe<Gate>
    gateEnabled: Scalars['Boolean']
    /** The icon for the catalog. May be rendered in its media row titles. */
    icon?: Maybe<Image>
    id: Scalars['ID']
    inheritedGate?: Maybe<Gate>
    name: Scalars['String']
    publishOn?: Maybe<Scalars['DateTime']>
    status: EntryStatus
    tags: Array<Maybe<Tag>>
    updatedAt: Scalars['DateTime']
  }

export type CatalogInput = {
  gates?: InputMaybe<Array<GateType>>
  productIds?: InputMaybe<Array<Scalars['String']>>
  search?: InputMaybe<Scalars['String']>
  skip?: InputMaybe<Scalars['Int']>
  status?: InputMaybe<EntryStatus>
  take?: InputMaybe<Scalars['Int']>
}

export type CatalogResponse = Res & {
  __typename?: 'CatalogResponse'
  /** Whether there are more items to fetch */
  hasMore: Scalars['Boolean']
  list: Array<Catalog>
  /** The total number of items */
  total: Scalars['Int']
}

export type Category = Entry &
  Record & {
    __typename?: 'Category'
    appliedGate?: Maybe<Gate>
    channel?: Maybe<Channel>
    createdAt: Scalars['DateTime']
    description?: Maybe<Scalars['String']>
    downloadsEnabled: Scalars['Boolean']
    enabled: Scalars['Boolean']
    expireOn?: Maybe<Scalars['DateTime']>
    gate?: Maybe<Gate>
    gateEnabled: Scalars['Boolean']
    id: Scalars['ID']
    inheritedGate?: Maybe<Gate>
    name: Scalars['String']
    publishOn?: Maybe<Scalars['DateTime']>
    status: EntryStatus
    tags: Array<Maybe<Tag>>
    updatedAt: Scalars['DateTime']
  }

export type CategoryConnectInput = {
  id?: InputMaybe<Scalars['ID']>
}

export type CategoryInput = {
  gates?: InputMaybe<Array<GateType>>
  productIds?: InputMaybe<Array<Scalars['String']>>
  search?: InputMaybe<Scalars['String']>
  skip?: InputMaybe<Scalars['Int']>
  status?: InputMaybe<EntryStatus>
  take?: InputMaybe<Scalars['Int']>
}

export type CategoryResponse = Res & {
  __typename?: 'CategoryResponse'
  /** Whether there are more items to fetch */
  hasMore: Scalars['Boolean']
  list: Array<Category>
  /** The total number of items */
  total: Scalars['Int']
}

export type Channel = Record & {
  __typename?: 'Channel'
  createdAt: Scalars['DateTime']
  description?: Maybe<Scalars['String']>
  id: Scalars['ID']
  logo?: Maybe<Image>
  name: Scalars['String']
  originId?: Maybe<Scalars['String']>
  sixteenNineCover?: Maybe<Image>
  tallBackdrop?: Maybe<Image>
  twoThreeCover?: Maybe<Image>
  updatedAt: Scalars['DateTime']
  wideBackdrop?: Maybe<Image>
}

export type ChannelInput = {
  search?: InputMaybe<Scalars['String']>
  skip?: InputMaybe<Scalars['Int']>
  take?: InputMaybe<Scalars['Int']>
}

export type ChannelResponse = Res & {
  __typename?: 'ChannelResponse'
  /** Whether there are more items to fetch */
  hasMore: Scalars['Boolean']
  list: Array<Channel>
  /** The total number of items */
  total: Scalars['Int']
}

export enum ComparisonOperator {
  Eq = 'eq',
  Neq = 'neq',
}

export type Content = {
  appliedGate?: Maybe<Gate>
  categories: Array<Category>
  createdAt: Scalars['DateTime']
  description?: Maybe<Scalars['String']>
  downloadsEnabled: Scalars['Boolean']
  /** Duration in seconds */
  duration?: Maybe<Scalars['Int']>
  enabled: Scalars['Boolean']
  expireOn?: Maybe<Scalars['DateTime']>
  featured: Scalars['Boolean']
  fileName?: Maybe<Scalars['String']>
  gate?: Maybe<Gate>
  gateEnabled: Scalars['Boolean']
  id: Scalars['ID']
  inheritedGate?: Maybe<Gate>
  /** The last view time and progress of the content by the user if authenticated */
  lastView?: Maybe<View>
  logo?: Maybe<Image>
  metadata: Array<Metadata>
  name: Scalars['String']
  originId?: Maybe<Scalars['String']>
  publishOn?: Maybe<Scalars['DateTime']>
  sixteenNineCover?: Maybe<Image>
  srcVideo?: Maybe<Scalars['String']>
  status: EntryStatus
  tags: Array<Maybe<Tag>>
  tallBackdrop?: Maybe<Image>
  twoThreeCover?: Maybe<Image>
  type: ContentType
  updatedAt: Scalars['DateTime']
  wideBackdrop?: Maybe<Image>
  workflowId?: Maybe<Scalars['String']>
  workflowStatus: WorkflowStatus
}

export type ContentInput = {
  categoryIds?: InputMaybe<Array<Scalars['String']>>
  gates?: InputMaybe<Array<GateType>>
  productIds?: InputMaybe<Array<Scalars['String']>>
  search?: InputMaybe<Scalars['String']>
  skip?: InputMaybe<Scalars['Int']>
  status?: InputMaybe<EntryStatus>
  take?: InputMaybe<Scalars['Int']>
}

export type ContentResponse = Res & {
  __typename?: 'ContentResponse'
  /** Whether there are more items to fetch */
  hasMore: Scalars['Boolean']
  list: Array<Maybe<Content>>
  /** The total number of items */
  total: Scalars['Int']
}

export enum ContentType {
  Event = 'event',
  Series = 'series',
  Video = 'video',
}

export enum Currency {
  Aud = 'aud',
  Brl = 'brl',
  Cad = 'cad',
  Chf = 'chf',
  Cny = 'cny',
  Eur = 'eur',
  Gbp = 'gbp',
  Hkd = 'hkd',
  Inr = 'inr',
  Jpy = 'jpy',
  Krw = 'krw',
  Mxn = 'mxn',
  Nok = 'nok',
  Nzd = 'nzd',
  Rub = 'rub',
  Sek = 'sek',
  Sgd = 'sgd',
  Try = 'try',
  Twd = 'twd',
  Usd = 'usd',
  Zar = 'zar',
}

/** The data for rendering a tenant's custom page */
export type CustomPage = {
  __typename?: 'CustomPage'
  /** The background image for the hero section at the top of the page */
  heroImage?: Maybe<Image>
  id: Scalars['ID']
  /** A list of media rows to be rendered on the page in descending order */
  rows: Array<Maybe<MediaRow>>
  title: Scalars['String']
}

export type CustomPageInput = {
  /** The ID of the custom page */
  id: Scalars['ID']
}

export type DeleteRecordsInput = {
  ids: Array<Scalars['String']>
}

export type Entry = {
  appliedGate?: Maybe<Gate>
  createdAt: Scalars['DateTime']
  description?: Maybe<Scalars['String']>
  downloadsEnabled: Scalars['Boolean']
  enabled: Scalars['Boolean']
  expireOn?: Maybe<Scalars['DateTime']>
  gate?: Maybe<Gate>
  gateEnabled: Scalars['Boolean']
  id: Scalars['ID']
  inheritedGate?: Maybe<Gate>
  name: Scalars['String']
  publishOn?: Maybe<Scalars['DateTime']>
  status: EntryStatus
  tags: Array<Maybe<Tag>>
  updatedAt: Scalars['DateTime']
}

export enum EntryStatus {
  Disabled = 'disabled',
  Expired = 'expired',
  Published = 'published',
  Unpublished = 'unpublished',
}

export type ErrorContentInput = {
  id: Scalars['ID']
  message?: InputMaybe<Scalars['String']>
}

export type Event = Content &
  Entry &
  Record & {
    __typename?: 'Event'
    appliedGate?: Maybe<Gate>
    categories: Array<Category>
    createdAt: Scalars['DateTime']
    description?: Maybe<Scalars['String']>
    downloadUrl?: Maybe<Scalars['String']>
    downloadsEnabled: Scalars['Boolean']
    /** Duration in seconds */
    duration?: Maybe<Scalars['Int']>
    enabled: Scalars['Boolean']
    expireOn?: Maybe<Scalars['DateTime']>
    featured: Scalars['Boolean']
    fileName?: Maybe<Scalars['String']>
    gate?: Maybe<Gate>
    gateEnabled: Scalars['Boolean']
    id: Scalars['ID']
    inheritedGate?: Maybe<Gate>
    /** The last view time and progress of the content by the user if authenticated */
    lastView?: Maybe<View>
    liveNow: Scalars['Boolean']
    logo?: Maybe<Image>
    metadata: Array<Metadata>
    name: Scalars['String']
    originId?: Maybe<Scalars['String']>
    publishOn?: Maybe<Scalars['DateTime']>
    scheduledEnd?: Maybe<Scalars['DateTime']>
    scheduledStart?: Maybe<Scalars['DateTime']>
    sixteenNineCover?: Maybe<Image>
    srcVideo?: Maybe<Scalars['String']>
    status: EntryStatus
    subtitle?: Maybe<Scalars['String']>
    tags: Array<Maybe<Tag>>
    tallBackdrop?: Maybe<Image>
    twoThreeCover?: Maybe<Image>
    type: ContentType
    updatedAt: Scalars['DateTime']
    wideBackdrop?: Maybe<Image>
    workflowId?: Maybe<Scalars['String']>
    workflowStatus: WorkflowStatus
  }

export type EventResponse = Res & {
  __typename?: 'EventResponse'
  /** Whether there are more items to fetch */
  hasMore: Scalars['Boolean']
  list: Array<Event>
  /** The total number of items */
  total: Scalars['Int']
}

/** The data for rendering an explore page */
export type Explore = {
  __typename?: 'Explore'
  rows: Array<Maybe<MediaRow>>
}

export type FilterGroup = Record & {
  __typename?: 'FilterGroup'
  createdAt: Scalars['DateTime']
  groups?: Maybe<Array<FilterGroup>>
  icon?: Maybe<Image>
  id: Scalars['ID']
  operator: LogicalOperator
  rules?: Maybe<Array<FilterRule>>
  updatedAt: Scalars['DateTime']
}

export type FilterGroupInput = {
  rules?: InputMaybe<Array<FilterRuleInput>>
}

export enum FilterProperty {
  Category = 'category',
}

export type FilterRule = {
  __typename?: 'FilterRule'
  categoryValue?: Maybe<Category>
  intValue: Scalars['Int']
  operator: ComparisonOperator
  property: FilterProperty
}

export type FilterRuleInput = {
  categoryValue?: InputMaybe<CategoryConnectInput>
}

export type Footer = {
  __typename?: 'Footer'
  copyright: Scalars['String']
  description?: Maybe<Scalars['String']>
  links: Array<Maybe<Link>>
}

export type ForgotPasswordInput = {
  email: Scalars['String']
  redirectUrl?: InputMaybe<Scalars['String']>
}

export type Gate = Record & {
  __typename?: 'Gate'
  createdAt: Scalars['DateTime']
  id: Scalars['ID']
  product?: Maybe<Product>
  type: GateType
  updatedAt: Scalars['DateTime']
}

export enum GateType {
  Ppv = 'ppv',
  Registration = 'registration',
  Subscription = 'subscription',
}

/** The data for rendering the header and footer of a site */
export type Global = {
  __typename?: 'Global'
  footer: Footer
  header: Header
}

export type Header = {
  __typename?: 'Header'
  brand: Link
  links: Array<Maybe<Link>>
  liveNow: Array<Maybe<Event>>
  more: Array<Maybe<Link>>
}

/** The data for rendering a home page */
export type Home = {
  __typename?: 'Home'
  /** A list of media items to be featured in the carousel at the top of the page */
  featured: Array<Maybe<MediaItem>>
  /** A list of media rows to be rendered on the page in descending order */
  rows: Array<Maybe<MediaRow>>
}

export type Image = {
  __typename?: 'Image'
  height?: Maybe<Scalars['Int']>
  set?: Maybe<Array<Image>>
  src: Scalars['String']
  width?: Maybe<Scalars['Int']>
}

export type IngestMessageInput = {
  message: Scalars['String']
  origin: MessageOrigin
  type: MessageType
}

export type InstallIntegrationInput = {
  id: Scalars['String']
}

export type Integration = {
  __typename?: 'Integration'
  authUrl?: Maybe<Scalars['String']>
  author?: Maybe<Scalars['String']>
  createdAt: Scalars['DateTime']
  description?: Maybe<Scalars['String']>
  id: Scalars['String']
  license?: Maybe<Scalars['String']>
  metadata: Array<Maybe<Metadata>>
  name: Scalars['String']
  published: Scalars['Boolean']
  supportUrl?: Maybe<Scalars['String']>
  updatedAt: Scalars['DateTime']
  version: Scalars['String']
}

export type IntegrationDetail = {
  __typename?: 'IntegrationDetail'
  authorized: Scalars['Boolean']
  createdAt: Scalars['DateTime']
  enabled: Scalars['Boolean']
  id: Scalars['String']
  integration: Integration
  updatedAt: Scalars['DateTime']
}

export type IntegrationDetailInput = {
  enabled?: InputMaybe<Scalars['Boolean']>
  search?: InputMaybe<Scalars['String']>
  skip?: InputMaybe<Scalars['Int']>
  take?: InputMaybe<Scalars['Int']>
}

export type IntegrationDetailResponse = Res & {
  __typename?: 'IntegrationDetailResponse'
  /** Whether there are more items to fetch */
  hasMore: Scalars['Boolean']
  list: Array<Maybe<IntegrationDetail>>
  /** The total number of items */
  total: Scalars['Int']
}

export type IntegrationInput = {
  search?: InputMaybe<Scalars['String']>
  skip?: InputMaybe<Scalars['Int']>
  take?: InputMaybe<Scalars['Int']>
}

export type IntegrationResponse = Res & {
  __typename?: 'IntegrationResponse'
  /** Whether there are more items to fetch */
  hasMore: Scalars['Boolean']
  list: Array<Maybe<Integration>>
  /** The total number of items */
  total: Scalars['Int']
}

export enum Interval {
  Day = 'day',
  Month = 'month',
  Week = 'week',
  Year = 'year',
}

export type Link = {
  __typename?: 'Link'
  icon?: Maybe<Image>
  route?: Maybe<Route>
  text: Scalars['String']
  type: LinkType
  url?: Maybe<Scalars['String']>
}

export enum LinkType {
  External = 'external',
  Internal = 'internal',
}

export enum LogicalOperator {
  And = 'and',
  Or = 'or',
}

export type LoginInput = {
  email: Scalars['String']
  password: Scalars['String']
}

/** A container type that can be either a video or event and rendered within a media row */
export type MediaItem = Event | Video

/** The input for querying a media item's details */
export type MediaItemInput = {
  /** The content ID tied to this media item */
  id: Scalars['ID']
}

/** A type that represents a row of media items to be rendered on a page */
export type MediaRow = Res & {
  __typename?: 'MediaRow'
  /** Whether there are more items to fetch */
  hasMore: Scalars['Boolean']
  /** An optional icon to be displayed left of the title */
  icon?: Maybe<Image>
  /** A paginated list of media items to be rendered in this row */
  items: Array<Maybe<MediaItem>>
  title: Scalars['String']
  /** The total number of items */
  total: Scalars['Int']
  type: MediaRowType
}

export type MediaRowInput = {
  /** The catalog ID tied to this media row */
  id: Scalars['ID']
  skip?: InputMaybe<Scalars['Int']>
  take?: InputMaybe<Scalars['Int']>
}

/** Live media rows are rendered slightly differently than standard media rows */
export enum MediaRowType {
  Live = 'live',
  Standard = 'standard',
}

export enum MessageOrigin {
  Sns = 'sns',
  Sqs = 'sqs',
}

/** The message response for simple mutations such as forgot password or reset password */
export type MessageResponse = {
  __typename?: 'MessageResponse'
  message: Scalars['String']
}

export enum MessageType {
  Vod = 'vod',
}

export type Metadata = {
  __typename?: 'Metadata'
  name: Scalars['String']
  value?: Maybe<Scalars['String']>
}

export type Mutation = {
  __typename?: 'Mutation'
  authorizeIntegrationDetail?: Maybe<IntegrationDetail>
  deleteCatalogs?: Maybe<MessageResponse>
  deleteCategories?: Maybe<MessageResponse>
  deletePages?: Maybe<MessageResponse>
  deleteRows?: Maybe<MessageResponse>
  deleteVideos?: Maybe<MessageResponse>
  errorContent?: Maybe<MessageResponse>
  forgotPassword?: Maybe<MessageResponse>
  ingestMessage?: Maybe<Content>
  login?: Maybe<User>
  logout?: Maybe<Scalars['Boolean']>
  registerTenant?: Maybe<RegisterTenant>
  report?: Maybe<Report>
  resetPassword?: Maybe<MessageResponse>
  updatePassword?: Maybe<MessageResponse>
  updateTenant?: Maybe<Tenant>
  updateUser?: Maybe<User>
  upsertCatalog?: Maybe<Catalog>
  upsertCategory?: Maybe<Category>
  upsertEvent?: Maybe<Event>
  upsertIntegrationDetail?: Maybe<IntegrationDetail>
  upsertRow?: Maybe<Row>
  upsertRows?: Maybe<Rows>
  upsertVideo?: Maybe<Video>
}

export type MutationAuthorizeIntegrationDetailArgs = {
  data: AuthorizeIntegrationDetailInput
}

export type MutationDeleteCatalogsArgs = {
  data: DeleteRecordsInput
}

export type MutationDeleteCategoriesArgs = {
  data: DeleteRecordsInput
}

export type MutationDeletePagesArgs = {
  data: DeleteRecordsInput
}

export type MutationDeleteRowsArgs = {
  data: DeleteRecordsInput
}

export type MutationDeleteVideosArgs = {
  data: DeleteRecordsInput
}

export type MutationErrorContentArgs = {
  data: ErrorContentInput
}

export type MutationForgotPasswordArgs = {
  data: ForgotPasswordInput
}

export type MutationIngestMessageArgs = {
  data: IngestMessageInput
}

export type MutationLoginArgs = {
  data: LoginInput
}

export type MutationRegisterTenantArgs = {
  data: RegisterTenantInput
}

export type MutationReportArgs = {
  data: ReportInput
}

export type MutationResetPasswordArgs = {
  data: ResetPasswordInput
}

export type MutationUpdatePasswordArgs = {
  data: UpdatePasswordInput
}

export type MutationUpdateTenantArgs = {
  data: UpdateTenantInput
}

export type MutationUpdateUserArgs = {
  data: UpdateUserInput
}

export type MutationUpsertCatalogArgs = {
  data: UpsertCatalogInput
}

export type MutationUpsertCategoryArgs = {
  data: UpsertCategoryInput
}

export type MutationUpsertEventArgs = {
  data: UpsertEventInput
}

export type MutationUpsertIntegrationDetailArgs = {
  data: UpsertIntegrationDetailInput
}

export type MutationUpsertRowArgs = {
  data: UpsertRowInput
}

export type MutationUpsertRowsArgs = {
  data: UpsertRowsInput
}

export type MutationUpsertVideoArgs = {
  data: UpsertVideoInput
}

/** The data for rendering an authenticated user's library page */
export type MyLibrary = {
  __typename?: 'MyLibrary'
  rows: Array<Maybe<MediaRow>>
}

export type NotionApiConfig = {
  __typename?: 'NotionApiConfig'
  authorizationSecret: Scalars['String']
  databases: RegisterNotionDatabases
}

export type NotionApiConfigInput = {
  authorizationSecret: Scalars['String']
  databases: RegisterNotionDatabasesInput
}

export type Page = Entry &
  Record & {
    __typename?: 'Page'
    appliedGate?: Maybe<Gate>
    bannerImage?: Maybe<Image>
    /** Describes how to filter the content for the catalog. */
    contentFilter?: Maybe<FilterGroup>
    createdAt: Scalars['DateTime']
    description?: Maybe<Scalars['String']>
    downloadsEnabled: Scalars['Boolean']
    enabled: Scalars['Boolean']
    expireOn?: Maybe<Scalars['DateTime']>
    featured: Scalars['Boolean']
    gate?: Maybe<Gate>
    gateEnabled: Scalars['Boolean']
    /** The icon for the catalog. May be rendered in its media row titles. */
    icon?: Maybe<Image>
    id: Scalars['ID']
    inHeader: Scalars['Boolean']
    inheritedGate?: Maybe<Gate>
    logo?: Maybe<Image>
    name: Scalars['String']
    order: Scalars['Int']
    publishOn?: Maybe<Scalars['DateTime']>
    rows?: Maybe<Array<MediaRow>>
    status: EntryStatus
    tags: Array<Maybe<Tag>>
    updatedAt: Scalars['DateTime']
  }

export type PageInput = {
  gates?: InputMaybe<Array<GateType>>
  inHeader?: InputMaybe<Scalars['Boolean']>
  productIds?: InputMaybe<Array<Scalars['String']>>
  search?: InputMaybe<Scalars['String']>
  skip?: InputMaybe<Scalars['Int']>
  status?: InputMaybe<EntryStatus>
  take?: InputMaybe<Scalars['Int']>
}

export type PageResponse = Res & {
  __typename?: 'PageResponse'
  /** Whether there are more items to fetch */
  hasMore: Scalars['Boolean']
  list: Array<Page>
  /** The total number of items */
  total: Scalars['Int']
}

export type Platform = {
  __typename?: 'Platform'
  description: Scalars['String']
  layout?: Maybe<Scalars['String']>
  manufacturer?: Maybe<Scalars['String']>
  name: Scalars['String']
  os: Scalars['String']
  product?: Maybe<Scalars['String']>
  version: Scalars['String']
}

export type Player = {
  __typename?: 'Player'
  contentId: Scalars['String']
  cookies: Array<PlayerSignedCookie>
  /** The user's current resume position, if any, in seconds */
  position?: Maybe<Scalars['Int']>
  /** The player's signed hls url */
  signedUrl: Scalars['String']
  /** The player's hls url */
  url: Scalars['String']
}

export type PlayerEvent = {
  __typename?: 'PlayerEvent'
  duration: Scalars['Int']
  durationBuffering: Scalars['Int']
  host: Scalars['String']
  platform: Platform
  playerVersion: Scalars['String']
  position: Scalars['Float']
  sessionId: Scalars['String']
  timestamp: Scalars['DateTime']
  type: PlayerEventType
  viewerId: Scalars['String']
}

export enum PlayerEventType {
  Buffer = 'buffer',
  Complete = 'complete',
  Error = 'error',
  Pause = 'pause',
  Play = 'play',
  Seek = 'seek',
  Time = 'time',
}

export type PlayerInput = {
  /** The ID of the content to play */
  contentId: Scalars['ID']
}

export type PlayerSignedCookie = {
  __typename?: 'PlayerSignedCookie'
  key: Scalars['String']
  value: Scalars['String']
}

export type Price = StripeObject & {
  __typename?: 'Price'
  active: Scalars['Boolean']
  amount: Scalars['Int']
  createdAt: Scalars['DateTime']
  currency: Currency
  id: Scalars['ID']
  livemode: Scalars['Boolean']
  metadata: Array<Metadata>
  object: StripeObjectType
  product: Scalars['String']
  recurring?: Maybe<Recurring>
  taxBehavior: TaxBehavior
  type: PriceType
  updatedAt: Scalars['DateTime']
}

export enum PriceType {
  OneTime = 'one_time',
  Recurring = 'recurring',
}

export type Product = StripeObject & {
  __typename?: 'Product'
  active: Scalars['Boolean']
  createdAt: Scalars['DateTime']
  defaultPrice?: Maybe<Price>
  description?: Maybe<Scalars['String']>
  id: Scalars['ID']
  images: Array<Image>
  livemode: Scalars['Boolean']
  metadata: Array<Metadata>
  name: Scalars['String']
  object: StripeObjectType
  prices: Array<Price>
  unitLabel?: Maybe<Scalars['String']>
  updatedAt: Scalars['DateTime']
  url?: Maybe<Scalars['String']>
}

export type ProductInput = {
  search?: InputMaybe<Scalars['String']>
}

export type ProductInputById = {
  id: Scalars['ID']
}

export type Query = {
  __typename?: 'Query'
  awsCredentials?: Maybe<AwsCredentials>
  catalog?: Maybe<Catalog>
  catalogs?: Maybe<CatalogResponse>
  categories?: Maybe<CategoryResponse>
  category?: Maybe<Category>
  /** Returns the data for rendering a tenant's custom page with given ID */
  customPage?: Maybe<CustomPage>
  events?: Maybe<EventResponse>
  /** Returns the data for rendering a tenant's explore page */
  explore?: Maybe<Explore>
  /** Returns the data for rendering a tenant's header and footer */
  global?: Maybe<Global>
  /** Returns the data for rendering a tenant's home page */
  home?: Maybe<Home>
  integrationDetails?: Maybe<IntegrationDetailResponse>
  integrations?: Maybe<IntegrationResponse>
  me?: Maybe<User>
  /** Returns the details for a media item */
  mediaItem?: Maybe<MediaItem>
  mediaRow?: Maybe<MediaRow>
  /** Returns the data for rendering a user's my library page */
  myLibrary?: Maybe<MyLibrary>
  pages?: Maybe<PageResponse>
  /** Returns the data for a content's video player such as the source url */
  player?: Maybe<Player>
  product?: Maybe<Product>
  products: Array<Product>
  row?: Maybe<Row>
  rows?: Maybe<RowResponse>
  stripeAccountLink?: Maybe<StripeAccountLink>
  tenant?: Maybe<Tenant>
  video?: Maybe<Video>
  videos?: Maybe<VideoResponse>
}

export type QueryAwsCredentialsArgs = {
  data: AwsCredentialsInput
}

export type QueryCatalogArgs = {
  data: RecordByIdInput
}

export type QueryCatalogsArgs = {
  data: CatalogInput
}

export type QueryCategoriesArgs = {
  data: CategoryInput
}

export type QueryCategoryArgs = {
  data: RecordByIdInput
}

export type QueryEventsArgs = {
  data: ContentInput
}

export type QueryIntegrationDetailsArgs = {
  data: IntegrationDetailInput
}

export type QueryIntegrationsArgs = {
  data: IntegrationDetailInput
}

export type QueryMediaRowArgs = {
  data: MediaRowInput
}

export type QueryPagesArgs = {
  data: PageInput
}

export type QueryPlayerArgs = {
  data: PlayerInput
}

export type QueryProductArgs = {
  data?: InputMaybe<ProductInputById>
}

export type QueryProductsArgs = {
  data: ProductInput
}

export type QueryRowArgs = {
  data: RecordByIdInput
}

export type QueryRowsArgs = {
  data: RowInput
}

export type QueryVideoArgs = {
  data: RecordByIdInput
}

export type QueryVideosArgs = {
  data: ContentInput
}

export type Record = {
  createdAt: Scalars['DateTime']
  id: Scalars['ID']
  updatedAt: Scalars['DateTime']
}

export type RecordByIdInput = {
  id?: InputMaybe<Scalars['String']>
}

export type Recurring = {
  __typename?: 'Recurring'
  interval: Interval
  intervalCount: Scalars['Int']
}

export type RegisterNotionDatabases = {
  __typename?: 'RegisterNotionDatabases'
  integrations: RegisterNotionDatabasesIntegrations
}

export type RegisterNotionDatabasesInput = {
  integrations: RegisterNotionDatabasesIntegrationsInput
}

export type RegisterNotionDatabasesIntegrations = {
  __typename?: 'RegisterNotionDatabasesIntegrations'
  id: Scalars['String']
}

export type RegisterNotionDatabasesIntegrationsInput = {
  id: Scalars['String']
}

export type RegisterTenant = {
  __typename?: 'RegisterTenant'
  api: Api
  id: Scalars['String']
  name: Scalars['String']
  notion?: Maybe<NotionApiConfig>
  owner: User
  password?: Maybe<Scalars['String']>
  stripeAccountId: Scalars['String']
  stripeCustomerId: Scalars['String']
  subscribe: Scalars['Boolean']
  vodDestBucket?: Maybe<Scalars['String']>
  vodSrcBucket?: Maybe<Scalars['String']>
  vodStackName?: Maybe<Scalars['String']>
}

export type RegisterTenantInput = {
  api?: InputMaybe<ApiInput>
  name: Scalars['String']
  notion?: InputMaybe<NotionApiConfigInput>
  owner: RegisterUserInput
  stripeAccountId: Scalars['String']
  stripeCustomerId?: InputMaybe<Scalars['String']>
  subscribe: Scalars['Boolean']
  vodDestBucket?: InputMaybe<Scalars['String']>
  vodSrcBucket?: InputMaybe<Scalars['String']>
  vodStackName?: InputMaybe<Scalars['String']>
}

export type RegisterUserInput = {
  email: Scalars['String']
  firstName: Scalars['String']
  lastName: Scalars['String']
  password: Scalars['String']
}

export type Report = {
  __typename?: 'Report'
  action: Scalars['String']
  dayOfWeek: Scalars['Int']
  duration: Scalars['Int']
  durationBuffering?: Maybe<Scalars['Int']>
  hourOfDay: Scalars['Int']
  /** A UUID that identifies the video player */
  playerId: Scalars['String']
  position: Scalars['Int']
  timestamp: Scalars['DateTime']
  /** Either the user ID of the viewer or a UUID that identifies the viewer when not authenticated */
  viewerId: Scalars['String']
}

export type ReportInput = {
  /** The day of the week in local time that the report was generated */
  dayOfWeek: Scalars['DateTime']
  duration: Scalars['Int']
  durationBuffering?: InputMaybe<Scalars['Int']>
  /** The hour of the day in local time that the report was generated */
  hourOfDay: Scalars['DateTime']
  /** A UUID that identifies the video player */
  playerId: Scalars['String']
  position: Scalars['Int']
  /** The time the report was generated */
  timestamp: Scalars['DateTime']
  /** A UUID that identifies the viewer when not authenticated, otherwise the authenticated user ID is used instead */
  viewerId?: InputMaybe<Scalars['String']>
}

export type Res = {
  /** Whether there are more items to fetch */
  hasMore: Scalars['Boolean']
  /** The total number of items */
  total: Scalars['Int']
}

export type ResetPasswordInput = {
  password: Scalars['String']
  token: Scalars['String']
}

export enum Role {
  Admin = 'admin',
  Owner = 'owner',
}

export type Route = {
  __typename?: 'Route'
  pageId: Scalars['String']
  path: Scalars['String']
}

/** A type that represents a row of media items to be rendered on a page */
export type Row = Record & {
  __typename?: 'Row'
  catalog?: Maybe<Catalog>
  createdAt: Scalars['DateTime']
  id: Scalars['ID']
  name: Scalars['String']
  order: Scalars['Int']
  page: RowPage
  /** The title of the row which defaults to either the category name or the name of the automated row. */
  title: Scalars['String']
  type: RowType
  updatedAt: Scalars['DateTime']
}

export type RowInput = {
  customPageId?: InputMaybe<Scalars['String']>
  page: RowPage
  search?: InputMaybe<Scalars['String']>
  skip?: InputMaybe<Scalars['Int']>
  take?: InputMaybe<Scalars['Int']>
}

/** The page that the rows are rendered on. */
export enum RowPage {
  Custom = 'custom',
  Explore = 'explore',
  Home = 'home',
}

export type RowResponse = Res & {
  __typename?: 'RowResponse'
  /** Whether there are more items to fetch */
  hasMore: Scalars['Boolean']
  list: Array<Row>
  /** The total number of items */
  total: Scalars['Int']
}

/** Catalog rows pull their content from the catalog's set of filters, otherwise the other types are automatic. */
export enum RowType {
  Catalog = 'catalog',
  KeepWatching = 'keepWatching',
  New = 'new',
  Recent = 'recent',
  Upcoming = 'upcoming',
}

export type Rows = {
  __typename?: 'Rows'
  rows: Array<Row>
}

export type StripeAccountLink = {
  __typename?: 'StripeAccountLink'
  createdAt: Scalars['DateTime']
  object: StripeObjectType
  url: Scalars['String']
}

export type StripeObject = {
  active: Scalars['Boolean']
  createdAt: Scalars['DateTime']
  id: Scalars['ID']
  livemode: Scalars['Boolean']
  metadata: Array<Metadata>
  object: StripeObjectType
  updatedAt: Scalars['DateTime']
}

export enum StripeObjectType {
  Account = 'account',
  Balance = 'balance',
  BalanceTransaction = 'balance_transaction',
  Charge = 'charge',
  CountrySpec = 'country_spec',
  Coupon = 'coupon',
  Customer = 'customer',
  Dispute = 'dispute',
  Event = 'event',
  File = 'file',
  FileLink = 'file_link',
  Invoice = 'invoice',
  InvoiceItem = 'invoice_item',
  LineItem = 'line_item',
  LoginLink = 'login_link',
  PaymentIntent = 'payment_intent',
  PaymentMethod = 'payment_method',
  Payout = 'payout',
  Plan = 'plan',
  Price = 'price',
  Product = 'product',
  Refund = 'refund',
  ReportingReportType = 'reporting_report_type',
  Review = 'review',
  SetupIntent = 'setup_intent',
  Sku = 'sku',
  Source = 'source',
  Subscription = 'subscription',
  SubscriptionItem = 'subscription_item',
  TaxId = 'tax_id',
  TaxRate = 'tax_rate',
  Topup = 'topup',
  Transfer = 'transfer',
  TransferReversal = 'transfer_reversal',
  UsageRecord = 'usage_record',
  UsageRecordSummary = 'usage_record_summary',
}

export type Tag = {
  __typename?: 'Tag'
  color?: Maybe<Scalars['String']>
  createdAt: Scalars['DateTime']
  id: Scalars['ID']
  name: Scalars['String']
  updatedAt: Scalars['DateTime']
}

export enum TaxBehavior {
  Exclusive = 'exclusive',
  Inclusive = 'inclusive',
  Unspecified = 'unspecified',
}

export type Tenant = Record & {
  __typename?: 'Tenant'
  api: ApiType
  createdAt: Scalars['DateTime']
  id: Scalars['ID']
  name: Scalars['String']
  updatedAt: Scalars['DateTime']
  users: Array<Maybe<User>>
}

export type UpdatePasswordInput = {
  currentPassword: Scalars['String']
  newPassword: Scalars['String']
}

export type UpdateTenantInput = {
  name: Scalars['String']
}

export type UpdateUserInput = {
  email?: InputMaybe<Scalars['String']>
  firstName?: InputMaybe<Scalars['String']>
  id: Scalars['String']
  lastName?: InputMaybe<Scalars['String']>
}

export type UpsertCatalogInput = {
  categoryIds: Array<Scalars['String']>
  description?: InputMaybe<Scalars['String']>
  enabled: Scalars['Boolean']
  expireOn?: InputMaybe<Scalars['DateTime']>
  gate?: InputMaybe<GateType>
  gateEnabled: Scalars['Boolean']
  id?: InputMaybe<Scalars['ID']>
  name: Scalars['String']
  productId?: InputMaybe<Scalars['String']>
  publishOn?: InputMaybe<Scalars['DateTime']>
}

export type UpsertCategoryInput = {
  description?: InputMaybe<Scalars['String']>
  enabled: Scalars['Boolean']
  expireOn?: InputMaybe<Scalars['DateTime']>
  gate?: InputMaybe<GateType>
  gateEnabled: Scalars['Boolean']
  id?: InputMaybe<Scalars['ID']>
  name: Scalars['String']
  productId?: InputMaybe<Scalars['String']>
  publishOn?: InputMaybe<Scalars['DateTime']>
}

export type UpsertEventInput = {
  categoryIds?: InputMaybe<Array<Scalars['String']>>
  description?: InputMaybe<Scalars['String']>
  downloadsEnabled?: InputMaybe<Scalars['Boolean']>
  enabled?: InputMaybe<Scalars['Boolean']>
  expireOn?: InputMaybe<Scalars['DateTime']>
  featured: Scalars['Boolean']
  fileName?: InputMaybe<Scalars['String']>
  gate?: InputMaybe<GateType>
  gateEnabled: Scalars['Boolean']
  id?: InputMaybe<Scalars['ID']>
  logoFileName?: InputMaybe<Scalars['String']>
  metadata?: InputMaybe<Array<UpsertMetadataInput>>
  name: Scalars['String']
  productId?: InputMaybe<Scalars['String']>
  publishOn?: InputMaybe<Scalars['DateTime']>
  scheduledEnd?: InputMaybe<Scalars['DateTime']>
  scheduledStart?: InputMaybe<Scalars['DateTime']>
  sixteenNineCoverFileName?: InputMaybe<Scalars['String']>
  subtitle?: InputMaybe<Scalars['String']>
  tags?: InputMaybe<Array<UpsertTagInput>>
  tallBackdropFileName?: InputMaybe<Scalars['String']>
  twoThreeCoverFileName?: InputMaybe<Scalars['String']>
  wideBackdropFileName?: InputMaybe<Scalars['String']>
}

export type UpsertGateInput = {
  description?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  name: Scalars['String']
  originId?: InputMaybe<Scalars['String']>
  type: GateType
}

export type UpsertIntegrationDetailInput = {
  enabled?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Scalars['ID']>
  integrationId?: InputMaybe<Scalars['Int']>
}

export type UpsertMetadataInput = {
  name: Scalars['String']
  value: Scalars['String']
}

export type UpsertRowInput = {
  catalogId?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['ID']>
  order?: InputMaybe<Scalars['Int']>
  page: RowPage
  title: Scalars['String']
  type: RowType
}

export type UpsertRowsInput = {
  rows: Array<UpsertRowInput>
}

export type UpsertTagInput = {
  color?: InputMaybe<Scalars['String']>
  name: Scalars['String']
}

export type UpsertVideoInput = {
  categoryIds?: InputMaybe<Array<Scalars['String']>>
  description?: InputMaybe<Scalars['String']>
  downloadsEnabled?: InputMaybe<Scalars['Boolean']>
  enabled?: InputMaybe<Scalars['Boolean']>
  expireOn?: InputMaybe<Scalars['DateTime']>
  featured?: InputMaybe<Scalars['Boolean']>
  fileName?: InputMaybe<Scalars['String']>
  gate?: InputMaybe<GateType>
  gateEnabled: Scalars['Boolean']
  id?: InputMaybe<Scalars['ID']>
  logoFileName?: InputMaybe<Scalars['String']>
  metadata?: InputMaybe<Array<UpsertMetadataInput>>
  name: Scalars['String']
  productId?: InputMaybe<Scalars['String']>
  publishOn?: InputMaybe<Scalars['DateTime']>
  sixteenNineCoverFileName?: InputMaybe<Scalars['String']>
  subtitle?: InputMaybe<Scalars['String']>
  tags?: InputMaybe<Array<UpsertTagInput>>
  tallBackdropFileName?: InputMaybe<Scalars['String']>
  twoThreeCoverFileName?: InputMaybe<Scalars['String']>
  wideBackdropFileName?: InputMaybe<Scalars['String']>
}

export type User = {
  __typename?: 'User'
  createdAt: Scalars['DateTime']
  email: Scalars['String']
  firstName?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  lastName?: Maybe<Scalars['String']>
  roles: Array<Role>
  tenant?: Maybe<Tenant>
  token?: Maybe<Scalars['String']>
  updatedAt: Scalars['DateTime']
}

export type UserInput = {
  search?: InputMaybe<Scalars['String']>
  skip?: InputMaybe<Scalars['Int']>
  take?: InputMaybe<Scalars['Int']>
}

export type UserResponse = Res & {
  __typename?: 'UserResponse'
  /** Whether there are more items to fetch */
  hasMore: Scalars['Boolean']
  list: Array<User>
  /** The total number of items */
  total: Scalars['Int']
}

export type UserRole = {
  __typename?: 'UserRole'
  name: Role
}

export type Video = Content &
  Entry &
  Record & {
    __typename?: 'Video'
    appliedGate?: Maybe<Gate>
    categories: Array<Category>
    createdAt: Scalars['DateTime']
    description?: Maybe<Scalars['String']>
    downloadUrl?: Maybe<Scalars['String']>
    downloadsEnabled: Scalars['Boolean']
    /** Duration in seconds */
    duration?: Maybe<Scalars['Int']>
    enabled: Scalars['Boolean']
    expireOn?: Maybe<Scalars['DateTime']>
    featured: Scalars['Boolean']
    fileName?: Maybe<Scalars['String']>
    gate?: Maybe<Gate>
    gateEnabled: Scalars['Boolean']
    id: Scalars['ID']
    inheritedGate?: Maybe<Gate>
    /** The last view time and progress of the content by the user if authenticated */
    lastView?: Maybe<View>
    logo?: Maybe<Image>
    metadata: Array<Metadata>
    name: Scalars['String']
    originId?: Maybe<Scalars['String']>
    publishOn?: Maybe<Scalars['DateTime']>
    sixteenNineCover?: Maybe<Image>
    srcVideo?: Maybe<Scalars['String']>
    status: EntryStatus
    subtitle?: Maybe<Scalars['String']>
    tags: Array<Maybe<Tag>>
    tallBackdrop?: Maybe<Image>
    twoThreeCover?: Maybe<Image>
    type: ContentType
    updatedAt: Scalars['DateTime']
    wideBackdrop?: Maybe<Image>
    workflowId?: Maybe<Scalars['String']>
    workflowStatus: WorkflowStatus
  }

export type VideoResponse = Res & {
  __typename?: 'VideoResponse'
  /** Whether there are more items to fetch */
  hasMore: Scalars['Boolean']
  list: Array<Video>
  /** The total number of items */
  total: Scalars['Int']
}

export type View = {
  __typename?: 'View'
  content?: Maybe<Content>
  /** The date and time the user last viewed the content */
  lastViewedAt: Scalars['DateTime']
  /** The user's progress on the content in seconds */
  progress: Scalars['Int']
}

export enum WorkflowStatus {
  Complete = 'complete',
  Error = 'error',
  None = 'none',
  Processing = 'processing',
}

export type VideoFieldsFragment = {
  __typename?: 'Video'
  id: string
  type: ContentType
  status: EntryStatus
  name: string
  fileName?: string | null
  srcVideo?: string | null
  subtitle?: string | null
  featured: boolean
  publishOn?: any | null
  expireOn?: any | null
  description?: string | null
  originId?: string | null
  gateEnabled: boolean
  createdAt: any
  downloadsEnabled: boolean
  enabled: boolean
  updatedAt: any
  workflowStatus: WorkflowStatus
  sixteenNineCover?: { __typename?: 'Image'; src: string } | null
  categories: Array<{ __typename?: 'Category'; id: string; name: string }>
  gate?: {
    __typename?: 'Gate'
    id: string
    type: GateType
    product?: { __typename?: 'Product'; id: string; name: string } | null
  } | null
  appliedGate?: {
    __typename?: 'Gate'
    id: string
    type: GateType
    product?: { __typename?: 'Product'; id: string; name: string } | null
  } | null
  inheritedGate?: {
    __typename?: 'Gate'
    id: string
    type: GateType
    product?: { __typename?: 'Product'; id: string; name: string } | null
  } | null
  tags: Array<{ __typename?: 'Tag'; id: string; name: string } | null>
}

export type EventFieldsFragment = {
  __typename?: 'Event'
  id: string
  type: ContentType
  status: EntryStatus
  name: string
  fileName?: string | null
  srcVideo?: string | null
  subtitle?: string | null
  featured: boolean
  publishOn?: any | null
  expireOn?: any | null
  scheduledStart?: any | null
  scheduledEnd?: any | null
  description?: string | null
  gateEnabled: boolean
  createdAt: any
  downloadsEnabled: boolean
  enabled: boolean
  updatedAt: any
  sixteenNineCover?: { __typename?: 'Image'; src: string } | null
  categories: Array<{ __typename?: 'Category'; id: string; name: string }>
  gate?: {
    __typename?: 'Gate'
    id: string
    type: GateType
    product?: { __typename?: 'Product'; id: string; name: string } | null
  } | null
  appliedGate?: {
    __typename?: 'Gate'
    id: string
    type: GateType
    product?: { __typename?: 'Product'; id: string; name: string } | null
  } | null
  inheritedGate?: {
    __typename?: 'Gate'
    id: string
    type: GateType
    product?: { __typename?: 'Product'; id: string; name: string } | null
  } | null
  tags: Array<{ __typename?: 'Tag'; id: string; name: string } | null>
}

export type PlayerFieldsFragment = {
  __typename?: 'Player'
  contentId: string
  position?: number | null
  url: string
  signedUrl: string
  cookies: Array<{ __typename?: 'PlayerSignedCookie'; key: string; value: string }>
}

export type CategoryFieldsFragment = {
  __typename?: 'Category'
  id: string
  name: string
  description?: string | null
  status: EntryStatus
  publishOn?: any | null
  expireOn?: any | null
  gate?: {
    __typename?: 'Gate'
    id: string
    type: GateType
    product?: { __typename?: 'Product'; id: string; name: string } | null
  } | null
}

export type CatalogFieldsFragment = {
  __typename?: 'Catalog'
  id: string
  name: string
  description?: string | null
  status: EntryStatus
  publishOn?: any | null
  expireOn?: any | null
  gate?: {
    __typename?: 'Gate'
    id: string
    type: GateType
    product?: { __typename?: 'Product'; id: string; name: string } | null
  } | null
}

export type RowFieldsFragment = {
  __typename?: 'Row'
  id: string
  title: string
  order: number
  page: RowPage
  catalog?: { __typename?: 'Catalog'; id: string; name: string; description?: string | null } | null
}

export type AwsCredentialFieldsFragment = {
  __typename?: 'AWSCredentials'
  resourceName: string
  secretAccessKey: string
  sessionToken: string
  accessKeyId: string
}

export type LoginMutationVariables = Exact<{
  data: LoginInput
}>

export type LoginMutation = {
  __typename?: 'Mutation'
  login?: {
    __typename?: 'User'
    token?: string | null
    firstName?: string | null
    lastName?: string | null
    email: string
    roles: Array<Role>
  } | null
}

export type LogoutMutationVariables = Exact<{ [key: string]: never }>

export type LogoutMutation = { __typename?: 'Mutation'; logout?: boolean | null }

export type RegisterTenantMutationVariables = Exact<{
  data: RegisterTenantInput
}>

export type RegisterTenantMutation = {
  __typename?: 'Mutation'
  registerTenant?: {
    __typename?: 'RegisterTenant'
    name: string
    id: string
    api: { __typename?: 'Api'; type: ApiType }
    owner: {
      __typename?: 'User'
      firstName?: string | null
      lastName?: string | null
      email: string
      roles: Array<Role>
    }
  } | null
}

export type ForgotPasswordMutationVariables = Exact<{
  data: ForgotPasswordInput
}>

export type ForgotPasswordMutation = {
  __typename?: 'Mutation'
  forgotPassword?: { __typename?: 'MessageResponse'; message: string } | null
}

export type ResetPasswordMutationVariables = Exact<{
  data: ResetPasswordInput
}>

export type ResetPasswordMutation = {
  __typename?: 'Mutation'
  resetPassword?: { __typename?: 'MessageResponse'; message: string } | null
}

export type UpsertVideoMutationVariables = Exact<{
  data: UpsertVideoInput
}>

export type UpsertVideoMutation = {
  __typename?: 'Mutation'
  upsertVideo?: {
    __typename?: 'Video'
    id: string
    type: ContentType
    status: EntryStatus
    name: string
    fileName?: string | null
    srcVideo?: string | null
    subtitle?: string | null
    featured: boolean
    publishOn?: any | null
    expireOn?: any | null
    description?: string | null
    originId?: string | null
    gateEnabled: boolean
    createdAt: any
    downloadsEnabled: boolean
    enabled: boolean
    updatedAt: any
    workflowStatus: WorkflowStatus
    sixteenNineCover?: { __typename?: 'Image'; src: string } | null
    categories: Array<{ __typename?: 'Category'; id: string; name: string }>
    gate?: {
      __typename?: 'Gate'
      id: string
      type: GateType
      product?: { __typename?: 'Product'; id: string; name: string } | null
    } | null
    appliedGate?: {
      __typename?: 'Gate'
      id: string
      type: GateType
      product?: { __typename?: 'Product'; id: string; name: string } | null
    } | null
    inheritedGate?: {
      __typename?: 'Gate'
      id: string
      type: GateType
      product?: { __typename?: 'Product'; id: string; name: string } | null
    } | null
    tags: Array<{ __typename?: 'Tag'; id: string; name: string } | null>
  } | null
}

export type DeleteVideosMutationVariables = Exact<{
  data: DeleteRecordsInput
}>

export type DeleteVideosMutation = {
  __typename?: 'Mutation'
  deleteVideos?: { __typename?: 'MessageResponse'; message: string } | null
}

export type DeleteCatalogsMutationVariables = Exact<{
  data: DeleteRecordsInput
}>

export type DeleteCatalogsMutation = {
  __typename?: 'Mutation'
  deleteCatalogs?: { __typename?: 'MessageResponse'; message: string } | null
}

export type DeleteCategoriesMutationVariables = Exact<{
  data: DeleteRecordsInput
}>

export type DeleteCategoriesMutation = {
  __typename?: 'Mutation'
  deleteCategories?: { __typename?: 'MessageResponse'; message: string } | null
}

export type DeletePagesMutationVariables = Exact<{
  data: DeleteRecordsInput
}>

export type DeletePagesMutation = {
  __typename?: 'Mutation'
  deletePages?: { __typename?: 'MessageResponse'; message: string } | null
}

export type DeleteRowsMutationVariables = Exact<{
  data: DeleteRecordsInput
}>

export type DeleteRowsMutation = {
  __typename?: 'Mutation'
  deleteRows?: { __typename?: 'MessageResponse'; message: string } | null
}

export type UpsertCategoryMutationVariables = Exact<{
  data: UpsertCategoryInput
}>

export type UpsertCategoryMutation = {
  __typename?: 'Mutation'
  upsertCategory?: {
    __typename?: 'Category'
    id: string
    name: string
    description?: string | null
    status: EntryStatus
    publishOn?: any | null
    expireOn?: any | null
    gate?: {
      __typename?: 'Gate'
      id: string
      type: GateType
      product?: { __typename?: 'Product'; id: string; name: string } | null
    } | null
  } | null
}

export type UpsertCatalogMutationVariables = Exact<{
  data: UpsertCatalogInput
}>

export type UpsertCatalogMutation = {
  __typename?: 'Mutation'
  upsertCatalog?: {
    __typename?: 'Catalog'
    id: string
    name: string
    description?: string | null
    status: EntryStatus
    publishOn?: any | null
    expireOn?: any | null
    gate?: {
      __typename?: 'Gate'
      id: string
      type: GateType
      product?: { __typename?: 'Product'; id: string; name: string } | null
    } | null
  } | null
}

export type UpsertRowMutationVariables = Exact<{
  data: UpsertRowInput
}>

export type UpsertRowMutation = {
  __typename?: 'Mutation'
  upsertRow?: {
    __typename?: 'Row'
    id: string
    title: string
    order: number
    page: RowPage
    catalog?: { __typename?: 'Catalog'; id: string; name: string; description?: string | null } | null
  } | null
}

export type UpsertRowsMutationVariables = Exact<{
  data: UpsertRowsInput
}>

export type UpsertRowsMutation = {
  __typename?: 'Mutation'
  upsertRows?: {
    __typename?: 'Rows'
    rows: Array<{
      __typename?: 'Row'
      id: string
      title: string
      order: number
      page: RowPage
      catalog?: { __typename?: 'Catalog'; id: string; name: string; description?: string | null } | null
    }>
  } | null
}

export type UpdateTenantMutationVariables = Exact<{
  data: UpdateTenantInput
}>

export type UpdateTenantMutation = {
  __typename?: 'Mutation'
  updateTenant?: { __typename?: 'Tenant'; id: string; name: string } | null
}

export type UpdateUserMutationVariables = Exact<{
  data: UpdateUserInput
}>

export type UpdateUserMutation = {
  __typename?: 'Mutation'
  updateUser?: {
    __typename?: 'User'
    id?: string | null
    firstName?: string | null
    lastName?: string | null
    email: string
  } | null
}

export type UpdatePasswordMutationVariables = Exact<{
  data: UpdatePasswordInput
}>

export type UpdatePasswordMutation = {
  __typename?: 'Mutation'
  updatePassword?: { __typename?: 'MessageResponse'; message: string } | null
}

export type ErrorContentMutationVariables = Exact<{
  data: ErrorContentInput
}>

export type ErrorContentMutation = {
  __typename?: 'Mutation'
  errorContent?: { __typename?: 'MessageResponse'; message: string } | null
}

export type VideosQueryVariables = Exact<{
  data: ContentInput
}>

export type VideosQuery = {
  __typename?: 'Query'
  videos?: {
    __typename?: 'VideoResponse'
    hasMore: boolean
    total: number
    list: Array<{
      __typename?: 'Video'
      id: string
      type: ContentType
      status: EntryStatus
      name: string
      fileName?: string | null
      srcVideo?: string | null
      subtitle?: string | null
      featured: boolean
      publishOn?: any | null
      expireOn?: any | null
      description?: string | null
      originId?: string | null
      gateEnabled: boolean
      createdAt: any
      downloadsEnabled: boolean
      enabled: boolean
      updatedAt: any
      workflowStatus: WorkflowStatus
      sixteenNineCover?: { __typename?: 'Image'; src: string } | null
      categories: Array<{ __typename?: 'Category'; id: string; name: string }>
      gate?: {
        __typename?: 'Gate'
        id: string
        type: GateType
        product?: { __typename?: 'Product'; id: string; name: string } | null
      } | null
      appliedGate?: {
        __typename?: 'Gate'
        id: string
        type: GateType
        product?: { __typename?: 'Product'; id: string; name: string } | null
      } | null
      inheritedGate?: {
        __typename?: 'Gate'
        id: string
        type: GateType
        product?: { __typename?: 'Product'; id: string; name: string } | null
      } | null
      tags: Array<{ __typename?: 'Tag'; id: string; name: string } | null>
    }>
  } | null
}

export type VideoQueryVariables = Exact<{
  data: RecordByIdInput
}>

export type VideoQuery = {
  __typename?: 'Query'
  video?: {
    __typename?: 'Video'
    id: string
    type: ContentType
    status: EntryStatus
    name: string
    fileName?: string | null
    srcVideo?: string | null
    subtitle?: string | null
    featured: boolean
    publishOn?: any | null
    expireOn?: any | null
    description?: string | null
    originId?: string | null
    gateEnabled: boolean
    createdAt: any
    downloadsEnabled: boolean
    enabled: boolean
    updatedAt: any
    workflowStatus: WorkflowStatus
    sixteenNineCover?: { __typename?: 'Image'; src: string } | null
    categories: Array<{ __typename?: 'Category'; id: string; name: string }>
    gate?: {
      __typename?: 'Gate'
      id: string
      type: GateType
      product?: { __typename?: 'Product'; id: string; name: string } | null
    } | null
    appliedGate?: {
      __typename?: 'Gate'
      id: string
      type: GateType
      product?: { __typename?: 'Product'; id: string; name: string } | null
    } | null
    inheritedGate?: {
      __typename?: 'Gate'
      id: string
      type: GateType
      product?: { __typename?: 'Product'; id: string; name: string } | null
    } | null
    tags: Array<{ __typename?: 'Tag'; id: string; name: string } | null>
  } | null
}

export type PlayerQueryVariables = Exact<{
  data: PlayerInput
}>

export type PlayerQuery = {
  __typename?: 'Query'
  player?: {
    __typename?: 'Player'
    contentId: string
    position?: number | null
    url: string
    signedUrl: string
    cookies: Array<{ __typename?: 'PlayerSignedCookie'; key: string; value: string }>
  } | null
}

export type EventsQueryVariables = Exact<{
  data: ContentInput
}>

export type EventsQuery = {
  __typename?: 'Query'
  events?: {
    __typename?: 'EventResponse'
    hasMore: boolean
    total: number
    list: Array<{
      __typename?: 'Event'
      id: string
      type: ContentType
      status: EntryStatus
      name: string
      fileName?: string | null
      srcVideo?: string | null
      subtitle?: string | null
      featured: boolean
      publishOn?: any | null
      expireOn?: any | null
      scheduledStart?: any | null
      scheduledEnd?: any | null
      description?: string | null
      gateEnabled: boolean
      createdAt: any
      downloadsEnabled: boolean
      enabled: boolean
      updatedAt: any
      sixteenNineCover?: { __typename?: 'Image'; src: string } | null
      categories: Array<{ __typename?: 'Category'; id: string; name: string }>
      gate?: {
        __typename?: 'Gate'
        id: string
        type: GateType
        product?: { __typename?: 'Product'; id: string; name: string } | null
      } | null
      appliedGate?: {
        __typename?: 'Gate'
        id: string
        type: GateType
        product?: { __typename?: 'Product'; id: string; name: string } | null
      } | null
      inheritedGate?: {
        __typename?: 'Gate'
        id: string
        type: GateType
        product?: { __typename?: 'Product'; id: string; name: string } | null
      } | null
      tags: Array<{ __typename?: 'Tag'; id: string; name: string } | null>
    }>
  } | null
}

export type CategoryQueryVariables = Exact<{
  data: RecordByIdInput
}>

export type CategoryQuery = {
  __typename?: 'Query'
  category?: {
    __typename?: 'Category'
    enabled: boolean
    gateEnabled: boolean
    createdAt: any
    updatedAt: any
    id: string
    name: string
    description?: string | null
    status: EntryStatus
    publishOn?: any | null
    expireOn?: any | null
    gate?: {
      __typename?: 'Gate'
      id: string
      type: GateType
      product?: { __typename?: 'Product'; id: string; name: string } | null
    } | null
  } | null
}

export type CategoriesQueryVariables = Exact<{
  data: CategoryInput
}>

export type CategoriesQuery = {
  __typename?: 'Query'
  categories?: {
    __typename?: 'CategoryResponse'
    hasMore: boolean
    total: number
    list: Array<{
      __typename?: 'Category'
      id: string
      name: string
      description?: string | null
      status: EntryStatus
      publishOn?: any | null
      expireOn?: any | null
      gate?: {
        __typename?: 'Gate'
        id: string
        type: GateType
        product?: { __typename?: 'Product'; id: string; name: string } | null
      } | null
    }>
  } | null
}

export type CatalogQueryVariables = Exact<{
  data: RecordByIdInput
}>

export type CatalogQuery = {
  __typename?: 'Query'
  catalog?: {
    __typename?: 'Catalog'
    enabled: boolean
    gateEnabled: boolean
    createdAt: any
    updatedAt: any
    id: string
    name: string
    description?: string | null
    status: EntryStatus
    publishOn?: any | null
    expireOn?: any | null
    contentFilter?: {
      __typename?: 'FilterGroup'
      id: string
      operator: LogicalOperator
      rules?: Array<{
        __typename?: 'FilterRule'
        property: FilterProperty
        operator: ComparisonOperator
        categoryValue?: { __typename?: 'Category'; id: string; name: string } | null
      }> | null
    } | null
    gate?: {
      __typename?: 'Gate'
      id: string
      type: GateType
      product?: { __typename?: 'Product'; id: string; name: string } | null
    } | null
  } | null
}

export type CatalogsQueryVariables = Exact<{
  data: CatalogInput
}>

export type CatalogsQuery = {
  __typename?: 'Query'
  catalogs?: {
    __typename?: 'CatalogResponse'
    hasMore: boolean
    total: number
    list: Array<{
      __typename?: 'Catalog'
      id: string
      name: string
      description?: string | null
      status: EntryStatus
      publishOn?: any | null
      expireOn?: any | null
      gate?: {
        __typename?: 'Gate'
        id: string
        type: GateType
        product?: { __typename?: 'Product'; id: string; name: string } | null
      } | null
    }>
  } | null
}

export type PagesQueryVariables = Exact<{
  data: PageInput
}>

export type PagesQuery = {
  __typename?: 'Query'
  pages?: {
    __typename?: 'PageResponse'
    hasMore: boolean
    total: number
    list: Array<{
      __typename?: 'Page'
      id: string
      name: string
      description?: string | null
      status: EntryStatus
      publishOn?: any | null
      expireOn?: any | null
      gate?: {
        __typename?: 'Gate'
        id: string
        type: GateType
        product?: { __typename?: 'Product'; id: string; name: string } | null
      } | null
    }>
  } | null
}

export type RowQueryVariables = Exact<{
  data: RecordByIdInput
}>

export type RowQuery = {
  __typename?: 'Query'
  row?: {
    __typename?: 'Row'
    id: string
    title: string
    order: number
    page: RowPage
    catalog?: { __typename?: 'Catalog'; id: string; name: string; description?: string | null } | null
  } | null
}

export type RowsQueryVariables = Exact<{
  data: RowInput
}>

export type RowsQuery = {
  __typename?: 'Query'
  rows?: {
    __typename?: 'RowResponse'
    hasMore: boolean
    total: number
    list: Array<{
      __typename?: 'Row'
      id: string
      title: string
      order: number
      page: RowPage
      catalog?: { __typename?: 'Catalog'; id: string; name: string; description?: string | null } | null
    }>
  } | null
}

export type ProductsQueryVariables = Exact<{
  data: ProductInput
}>

export type ProductsQuery = {
  __typename?: 'Query'
  products: Array<{
    __typename?: 'Product'
    id: string
    name: string
    object: StripeObjectType
    unitLabel?: string | null
    url?: string | null
    description?: string | null
    active: boolean
    createdAt: any
    updatedAt: any
    livemode: boolean
    images: Array<{ __typename?: 'Image'; src: string }>
    metadata: Array<{ __typename?: 'Metadata'; name: string; value?: string | null }>
    defaultPrice?: {
      __typename?: 'Price'
      id: string
      active: boolean
      currency: Currency
      createdAt: any
      updatedAt: any
      amount: number
      livemode: boolean
      product: string
      taxBehavior: TaxBehavior
      object: StripeObjectType
      type: PriceType
      metadata: Array<{ __typename?: 'Metadata'; name: string; value?: string | null }>
      recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
    } | null
    prices: Array<{
      __typename?: 'Price'
      id: string
      active: boolean
      currency: Currency
      createdAt: any
      updatedAt: any
      amount: number
      livemode: boolean
      product: string
      taxBehavior: TaxBehavior
      object: StripeObjectType
      type: PriceType
      metadata: Array<{ __typename?: 'Metadata'; name: string; value?: string | null }>
      recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
    }>
  }>
}

export type StripeAccountLinkQueryVariables = Exact<{ [key: string]: never }>

export type StripeAccountLinkQuery = {
  __typename?: 'Query'
  stripeAccountLink?: { __typename?: 'StripeAccountLink'; url: string } | null
}

export type AwsCredentialsQueryVariables = Exact<{
  data: AwsCredentialsInput
}>

export type AwsCredentialsQuery = {
  __typename?: 'Query'
  awsCredentials?: {
    __typename?: 'AWSCredentials'
    resourceName: string
    secretAccessKey: string
    sessionToken: string
    accessKeyId: string
  } | null
}

export type TenantQueryVariables = Exact<{ [key: string]: never }>

export type TenantQuery = { __typename?: 'Query'; tenant?: { __typename?: 'Tenant'; id: string; name: string } | null }

export type MeQueryVariables = Exact<{ [key: string]: never }>

export type MeQuery = {
  __typename?: 'Query'
  me?: {
    __typename?: 'User'
    id?: string | null
    email: string
    firstName?: string | null
    lastName?: string | null
  } | null
}

export const VideoFieldsFragmentDoc = gql`
  fragment videoFields on Video {
    id
    type
    status
    name
    fileName
    srcVideo
    subtitle
    featured
    publishOn
    expireOn
    description
    originId
    sixteenNineCover {
      src
    }
    categories {
      id
      name
    }
    gateEnabled
    gate {
      id
      type
      product {
        id
        name
      }
    }
    appliedGate {
      id
      type
      product {
        id
        name
      }
    }
    inheritedGate {
      id
      type
      product {
        id
        name
      }
    }
    createdAt
    downloadsEnabled
    enabled
    tags {
      id
      name
    }
    updatedAt
    workflowStatus
  }
`
export const EventFieldsFragmentDoc = gql`
  fragment eventFields on Event {
    id
    type
    status
    name
    fileName
    srcVideo
    subtitle
    featured
    publishOn
    expireOn
    scheduledStart
    scheduledEnd
    description
    sixteenNineCover {
      src
    }
    categories {
      id
      name
    }
    gateEnabled
    gate {
      id
      type
      product {
        id
        name
      }
    }
    appliedGate {
      id
      type
      product {
        id
        name
      }
    }
    inheritedGate {
      id
      type
      product {
        id
        name
      }
    }
    createdAt
    downloadsEnabled
    enabled
    tags {
      id
      name
    }
    updatedAt
  }
`
export const PlayerFieldsFragmentDoc = gql`
  fragment playerFields on Player {
    contentId
    position
    url
    signedUrl
    cookies {
      key
      value
    }
  }
`
export const CategoryFieldsFragmentDoc = gql`
  fragment categoryFields on Category {
    id
    name
    description
    status
    publishOn
    expireOn
    gate {
      id
      type
      product {
        id
        name
      }
    }
  }
`
export const CatalogFieldsFragmentDoc = gql`
  fragment catalogFields on Catalog {
    id
    name
    description
    status
    publishOn
    expireOn
    gate {
      id
      type
      product {
        id
        name
      }
    }
  }
`
export const RowFieldsFragmentDoc = gql`
  fragment rowFields on Row {
    id
    title
    order
    page
    catalog {
      id
      name
      description
    }
  }
`
export const AwsCredentialFieldsFragmentDoc = gql`
  fragment AWSCredentialFields on AWSCredentials {
    resourceName
    secretAccessKey
    sessionToken
    accessKeyId
  }
`
export const LoginDocument = gql`
  mutation Login($data: LoginInput!) {
    login(data: $data) {
      token
      firstName
      lastName
      email
      roles
    }
  }
`
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options)
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>
export const LogoutDocument = gql`
  mutation Logout {
    logout
  }
`
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options)
}
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>
export const RegisterTenantDocument = gql`
  mutation RegisterTenant($data: RegisterTenantInput!) {
    registerTenant(data: $data) {
      name
      id
      api {
        type
      }
      owner {
        firstName
        lastName
        email
        roles
      }
    }
  }
`
export type RegisterTenantMutationFn = Apollo.MutationFunction<RegisterTenantMutation, RegisterTenantMutationVariables>

/**
 * __useRegisterTenantMutation__
 *
 * To run a mutation, you first call `useRegisterTenantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterTenantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerTenantMutation, { data, loading, error }] = useRegisterTenantMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRegisterTenantMutation(
  baseOptions?: Apollo.MutationHookOptions<RegisterTenantMutation, RegisterTenantMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RegisterTenantMutation, RegisterTenantMutationVariables>(RegisterTenantDocument, options)
}
export type RegisterTenantMutationHookResult = ReturnType<typeof useRegisterTenantMutation>
export type RegisterTenantMutationResult = Apollo.MutationResult<RegisterTenantMutation>
export type RegisterTenantMutationOptions = Apollo.BaseMutationOptions<
  RegisterTenantMutation,
  RegisterTenantMutationVariables
>
export const ForgotPasswordDocument = gql`
  mutation ForgotPassword($data: ForgotPasswordInput!) {
    forgotPassword(data: $data) {
      message
    }
  }
`
export type ForgotPasswordMutationFn = Apollo.MutationFunction<ForgotPasswordMutation, ForgotPasswordMutationVariables>

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useForgotPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, options)
}
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>
export type ForgotPasswordMutationResult = Apollo.MutationResult<ForgotPasswordMutation>
export type ForgotPasswordMutationOptions = Apollo.BaseMutationOptions<
  ForgotPasswordMutation,
  ForgotPasswordMutationVariables
>
export const ResetPasswordDocument = gql`
  mutation ResetPassword($data: ResetPasswordInput!) {
    resetPassword(data: $data) {
      message
    }
  }
`
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useResetPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options)
}
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>
export const UpsertVideoDocument = gql`
  mutation UpsertVideo($data: UpsertVideoInput!) {
    upsertVideo(data: $data) {
      ...videoFields
    }
  }
  ${VideoFieldsFragmentDoc}
`
export type UpsertVideoMutationFn = Apollo.MutationFunction<UpsertVideoMutation, UpsertVideoMutationVariables>

/**
 * __useUpsertVideoMutation__
 *
 * To run a mutation, you first call `useUpsertVideoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertVideoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertVideoMutation, { data, loading, error }] = useUpsertVideoMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpsertVideoMutation(
  baseOptions?: Apollo.MutationHookOptions<UpsertVideoMutation, UpsertVideoMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpsertVideoMutation, UpsertVideoMutationVariables>(UpsertVideoDocument, options)
}
export type UpsertVideoMutationHookResult = ReturnType<typeof useUpsertVideoMutation>
export type UpsertVideoMutationResult = Apollo.MutationResult<UpsertVideoMutation>
export type UpsertVideoMutationOptions = Apollo.BaseMutationOptions<UpsertVideoMutation, UpsertVideoMutationVariables>
export const DeleteVideosDocument = gql`
  mutation DeleteVideos($data: DeleteRecordsInput!) {
    deleteVideos(data: $data) {
      message
    }
  }
`
export type DeleteVideosMutationFn = Apollo.MutationFunction<DeleteVideosMutation, DeleteVideosMutationVariables>

/**
 * __useDeleteVideosMutation__
 *
 * To run a mutation, you first call `useDeleteVideosMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVideosMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVideosMutation, { data, loading, error }] = useDeleteVideosMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDeleteVideosMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteVideosMutation, DeleteVideosMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteVideosMutation, DeleteVideosMutationVariables>(DeleteVideosDocument, options)
}
export type DeleteVideosMutationHookResult = ReturnType<typeof useDeleteVideosMutation>
export type DeleteVideosMutationResult = Apollo.MutationResult<DeleteVideosMutation>
export type DeleteVideosMutationOptions = Apollo.BaseMutationOptions<
  DeleteVideosMutation,
  DeleteVideosMutationVariables
>
export const DeleteCatalogsDocument = gql`
  mutation DeleteCatalogs($data: DeleteRecordsInput!) {
    deleteCatalogs(data: $data) {
      message
    }
  }
`
export type DeleteCatalogsMutationFn = Apollo.MutationFunction<DeleteCatalogsMutation, DeleteCatalogsMutationVariables>

/**
 * __useDeleteCatalogsMutation__
 *
 * To run a mutation, you first call `useDeleteCatalogsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCatalogsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCatalogsMutation, { data, loading, error }] = useDeleteCatalogsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDeleteCatalogsMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteCatalogsMutation, DeleteCatalogsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteCatalogsMutation, DeleteCatalogsMutationVariables>(DeleteCatalogsDocument, options)
}
export type DeleteCatalogsMutationHookResult = ReturnType<typeof useDeleteCatalogsMutation>
export type DeleteCatalogsMutationResult = Apollo.MutationResult<DeleteCatalogsMutation>
export type DeleteCatalogsMutationOptions = Apollo.BaseMutationOptions<
  DeleteCatalogsMutation,
  DeleteCatalogsMutationVariables
>
export const DeleteCategoriesDocument = gql`
  mutation DeleteCategories($data: DeleteRecordsInput!) {
    deleteCategories(data: $data) {
      message
    }
  }
`
export type DeleteCategoriesMutationFn = Apollo.MutationFunction<
  DeleteCategoriesMutation,
  DeleteCategoriesMutationVariables
>

/**
 * __useDeleteCategoriesMutation__
 *
 * To run a mutation, you first call `useDeleteCategoriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCategoriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCategoriesMutation, { data, loading, error }] = useDeleteCategoriesMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDeleteCategoriesMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteCategoriesMutation, DeleteCategoriesMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteCategoriesMutation, DeleteCategoriesMutationVariables>(
    DeleteCategoriesDocument,
    options,
  )
}
export type DeleteCategoriesMutationHookResult = ReturnType<typeof useDeleteCategoriesMutation>
export type DeleteCategoriesMutationResult = Apollo.MutationResult<DeleteCategoriesMutation>
export type DeleteCategoriesMutationOptions = Apollo.BaseMutationOptions<
  DeleteCategoriesMutation,
  DeleteCategoriesMutationVariables
>
export const DeletePagesDocument = gql`
  mutation DeletePages($data: DeleteRecordsInput!) {
    deletePages(data: $data) {
      message
    }
  }
`
export type DeletePagesMutationFn = Apollo.MutationFunction<DeletePagesMutation, DeletePagesMutationVariables>

/**
 * __useDeletePagesMutation__
 *
 * To run a mutation, you first call `useDeletePagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePagesMutation, { data, loading, error }] = useDeletePagesMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDeletePagesMutation(
  baseOptions?: Apollo.MutationHookOptions<DeletePagesMutation, DeletePagesMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeletePagesMutation, DeletePagesMutationVariables>(DeletePagesDocument, options)
}
export type DeletePagesMutationHookResult = ReturnType<typeof useDeletePagesMutation>
export type DeletePagesMutationResult = Apollo.MutationResult<DeletePagesMutation>
export type DeletePagesMutationOptions = Apollo.BaseMutationOptions<DeletePagesMutation, DeletePagesMutationVariables>
export const DeleteRowsDocument = gql`
  mutation DeleteRows($data: DeleteRecordsInput!) {
    deleteRows(data: $data) {
      message
    }
  }
`
export type DeleteRowsMutationFn = Apollo.MutationFunction<DeleteRowsMutation, DeleteRowsMutationVariables>

/**
 * __useDeleteRowsMutation__
 *
 * To run a mutation, you first call `useDeleteRowsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRowsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRowsMutation, { data, loading, error }] = useDeleteRowsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDeleteRowsMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteRowsMutation, DeleteRowsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteRowsMutation, DeleteRowsMutationVariables>(DeleteRowsDocument, options)
}
export type DeleteRowsMutationHookResult = ReturnType<typeof useDeleteRowsMutation>
export type DeleteRowsMutationResult = Apollo.MutationResult<DeleteRowsMutation>
export type DeleteRowsMutationOptions = Apollo.BaseMutationOptions<DeleteRowsMutation, DeleteRowsMutationVariables>
export const UpsertCategoryDocument = gql`
  mutation UpsertCategory($data: UpsertCategoryInput!) {
    upsertCategory(data: $data) {
      ...categoryFields
    }
  }
  ${CategoryFieldsFragmentDoc}
`
export type UpsertCategoryMutationFn = Apollo.MutationFunction<UpsertCategoryMutation, UpsertCategoryMutationVariables>

/**
 * __useUpsertCategoryMutation__
 *
 * To run a mutation, you first call `useUpsertCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertCategoryMutation, { data, loading, error }] = useUpsertCategoryMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpsertCategoryMutation(
  baseOptions?: Apollo.MutationHookOptions<UpsertCategoryMutation, UpsertCategoryMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpsertCategoryMutation, UpsertCategoryMutationVariables>(UpsertCategoryDocument, options)
}
export type UpsertCategoryMutationHookResult = ReturnType<typeof useUpsertCategoryMutation>
export type UpsertCategoryMutationResult = Apollo.MutationResult<UpsertCategoryMutation>
export type UpsertCategoryMutationOptions = Apollo.BaseMutationOptions<
  UpsertCategoryMutation,
  UpsertCategoryMutationVariables
>
export const UpsertCatalogDocument = gql`
  mutation UpsertCatalog($data: UpsertCatalogInput!) {
    upsertCatalog(data: $data) {
      ...catalogFields
    }
  }
  ${CatalogFieldsFragmentDoc}
`
export type UpsertCatalogMutationFn = Apollo.MutationFunction<UpsertCatalogMutation, UpsertCatalogMutationVariables>

/**
 * __useUpsertCatalogMutation__
 *
 * To run a mutation, you first call `useUpsertCatalogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertCatalogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertCatalogMutation, { data, loading, error }] = useUpsertCatalogMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpsertCatalogMutation(
  baseOptions?: Apollo.MutationHookOptions<UpsertCatalogMutation, UpsertCatalogMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpsertCatalogMutation, UpsertCatalogMutationVariables>(UpsertCatalogDocument, options)
}
export type UpsertCatalogMutationHookResult = ReturnType<typeof useUpsertCatalogMutation>
export type UpsertCatalogMutationResult = Apollo.MutationResult<UpsertCatalogMutation>
export type UpsertCatalogMutationOptions = Apollo.BaseMutationOptions<
  UpsertCatalogMutation,
  UpsertCatalogMutationVariables
>
export const UpsertRowDocument = gql`
  mutation UpsertRow($data: UpsertRowInput!) {
    upsertRow(data: $data) {
      ...rowFields
    }
  }
  ${RowFieldsFragmentDoc}
`
export type UpsertRowMutationFn = Apollo.MutationFunction<UpsertRowMutation, UpsertRowMutationVariables>

/**
 * __useUpsertRowMutation__
 *
 * To run a mutation, you first call `useUpsertRowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertRowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertRowMutation, { data, loading, error }] = useUpsertRowMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpsertRowMutation(
  baseOptions?: Apollo.MutationHookOptions<UpsertRowMutation, UpsertRowMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpsertRowMutation, UpsertRowMutationVariables>(UpsertRowDocument, options)
}
export type UpsertRowMutationHookResult = ReturnType<typeof useUpsertRowMutation>
export type UpsertRowMutationResult = Apollo.MutationResult<UpsertRowMutation>
export type UpsertRowMutationOptions = Apollo.BaseMutationOptions<UpsertRowMutation, UpsertRowMutationVariables>
export const UpsertRowsDocument = gql`
  mutation UpsertRows($data: UpsertRowsInput!) {
    upsertRows(data: $data) {
      rows {
        ...rowFields
      }
    }
  }
  ${RowFieldsFragmentDoc}
`
export type UpsertRowsMutationFn = Apollo.MutationFunction<UpsertRowsMutation, UpsertRowsMutationVariables>

/**
 * __useUpsertRowsMutation__
 *
 * To run a mutation, you first call `useUpsertRowsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertRowsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertRowsMutation, { data, loading, error }] = useUpsertRowsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpsertRowsMutation(
  baseOptions?: Apollo.MutationHookOptions<UpsertRowsMutation, UpsertRowsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpsertRowsMutation, UpsertRowsMutationVariables>(UpsertRowsDocument, options)
}
export type UpsertRowsMutationHookResult = ReturnType<typeof useUpsertRowsMutation>
export type UpsertRowsMutationResult = Apollo.MutationResult<UpsertRowsMutation>
export type UpsertRowsMutationOptions = Apollo.BaseMutationOptions<UpsertRowsMutation, UpsertRowsMutationVariables>
export const UpdateTenantDocument = gql`
  mutation UpdateTenant($data: UpdateTenantInput!) {
    updateTenant(data: $data) {
      id
      name
    }
  }
`
export type UpdateTenantMutationFn = Apollo.MutationFunction<UpdateTenantMutation, UpdateTenantMutationVariables>

/**
 * __useUpdateTenantMutation__
 *
 * To run a mutation, you first call `useUpdateTenantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTenantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTenantMutation, { data, loading, error }] = useUpdateTenantMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateTenantMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateTenantMutation, UpdateTenantMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateTenantMutation, UpdateTenantMutationVariables>(UpdateTenantDocument, options)
}
export type UpdateTenantMutationHookResult = ReturnType<typeof useUpdateTenantMutation>
export type UpdateTenantMutationResult = Apollo.MutationResult<UpdateTenantMutation>
export type UpdateTenantMutationOptions = Apollo.BaseMutationOptions<
  UpdateTenantMutation,
  UpdateTenantMutationVariables
>
export const UpdateUserDocument = gql`
  mutation UpdateUser($data: UpdateUserInput!) {
    updateUser(data: $data) {
      id
      firstName
      lastName
      email
    }
  }
`
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options)
}
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>
export const UpdatePasswordDocument = gql`
  mutation UpdatePassword($data: UpdatePasswordInput!) {
    updatePassword(data: $data) {
      message
    }
  }
`
export type UpdatePasswordMutationFn = Apollo.MutationFunction<UpdatePasswordMutation, UpdatePasswordMutationVariables>

/**
 * __useUpdatePasswordMutation__
 *
 * To run a mutation, you first call `useUpdatePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePasswordMutation, { data, loading, error }] = useUpdatePasswordMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdatePasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdatePasswordMutation, UpdatePasswordMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdatePasswordMutation, UpdatePasswordMutationVariables>(UpdatePasswordDocument, options)
}
export type UpdatePasswordMutationHookResult = ReturnType<typeof useUpdatePasswordMutation>
export type UpdatePasswordMutationResult = Apollo.MutationResult<UpdatePasswordMutation>
export type UpdatePasswordMutationOptions = Apollo.BaseMutationOptions<
  UpdatePasswordMutation,
  UpdatePasswordMutationVariables
>
export const ErrorContentDocument = gql`
  mutation ErrorContent($data: ErrorContentInput!) {
    errorContent(data: $data) {
      message
    }
  }
`
export type ErrorContentMutationFn = Apollo.MutationFunction<ErrorContentMutation, ErrorContentMutationVariables>

/**
 * __useErrorContentMutation__
 *
 * To run a mutation, you first call `useErrorContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useErrorContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [errorContentMutation, { data, loading, error }] = useErrorContentMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useErrorContentMutation(
  baseOptions?: Apollo.MutationHookOptions<ErrorContentMutation, ErrorContentMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ErrorContentMutation, ErrorContentMutationVariables>(ErrorContentDocument, options)
}
export type ErrorContentMutationHookResult = ReturnType<typeof useErrorContentMutation>
export type ErrorContentMutationResult = Apollo.MutationResult<ErrorContentMutation>
export type ErrorContentMutationOptions = Apollo.BaseMutationOptions<
  ErrorContentMutation,
  ErrorContentMutationVariables
>
export const VideosDocument = gql`
  query Videos($data: ContentInput!) {
    videos(data: $data) {
      hasMore
      total
      list {
        ...videoFields
      }
    }
  }
  ${VideoFieldsFragmentDoc}
`

/**
 * __useVideosQuery__
 *
 * To run a query within a React component, call `useVideosQuery` and pass it any options that fit your needs.
 * When your component renders, `useVideosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVideosQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useVideosQuery(baseOptions: Apollo.QueryHookOptions<VideosQuery, VideosQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<VideosQuery, VideosQueryVariables>(VideosDocument, options)
}
export function useVideosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VideosQuery, VideosQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<VideosQuery, VideosQueryVariables>(VideosDocument, options)
}
export type VideosQueryHookResult = ReturnType<typeof useVideosQuery>
export type VideosLazyQueryHookResult = ReturnType<typeof useVideosLazyQuery>
export type VideosQueryResult = Apollo.QueryResult<VideosQuery, VideosQueryVariables>
export const VideoDocument = gql`
  query Video($data: RecordByIdInput!) {
    video(data: $data) {
      ...videoFields
    }
  }
  ${VideoFieldsFragmentDoc}
`

/**
 * __useVideoQuery__
 *
 * To run a query within a React component, call `useVideoQuery` and pass it any options that fit your needs.
 * When your component renders, `useVideoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVideoQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useVideoQuery(baseOptions: Apollo.QueryHookOptions<VideoQuery, VideoQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<VideoQuery, VideoQueryVariables>(VideoDocument, options)
}
export function useVideoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VideoQuery, VideoQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<VideoQuery, VideoQueryVariables>(VideoDocument, options)
}
export type VideoQueryHookResult = ReturnType<typeof useVideoQuery>
export type VideoLazyQueryHookResult = ReturnType<typeof useVideoLazyQuery>
export type VideoQueryResult = Apollo.QueryResult<VideoQuery, VideoQueryVariables>
export const PlayerDocument = gql`
  query Player($data: PlayerInput!) {
    player(data: $data) {
      ...playerFields
    }
  }
  ${PlayerFieldsFragmentDoc}
`

/**
 * __usePlayerQuery__
 *
 * To run a query within a React component, call `usePlayerQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlayerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlayerQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function usePlayerQuery(baseOptions: Apollo.QueryHookOptions<PlayerQuery, PlayerQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PlayerQuery, PlayerQueryVariables>(PlayerDocument, options)
}
export function usePlayerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlayerQuery, PlayerQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<PlayerQuery, PlayerQueryVariables>(PlayerDocument, options)
}
export type PlayerQueryHookResult = ReturnType<typeof usePlayerQuery>
export type PlayerLazyQueryHookResult = ReturnType<typeof usePlayerLazyQuery>
export type PlayerQueryResult = Apollo.QueryResult<PlayerQuery, PlayerQueryVariables>
export const EventsDocument = gql`
  query Events($data: ContentInput!) {
    events(data: $data) {
      hasMore
      total
      list {
        ...eventFields
      }
    }
  }
  ${EventFieldsFragmentDoc}
`

/**
 * __useEventsQuery__
 *
 * To run a query within a React component, call `useEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventsQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useEventsQuery(baseOptions: Apollo.QueryHookOptions<EventsQuery, EventsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<EventsQuery, EventsQueryVariables>(EventsDocument, options)
}
export function useEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EventsQuery, EventsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<EventsQuery, EventsQueryVariables>(EventsDocument, options)
}
export type EventsQueryHookResult = ReturnType<typeof useEventsQuery>
export type EventsLazyQueryHookResult = ReturnType<typeof useEventsLazyQuery>
export type EventsQueryResult = Apollo.QueryResult<EventsQuery, EventsQueryVariables>
export const CategoryDocument = gql`
  query Category($data: RecordByIdInput!) {
    category(data: $data) {
      ...categoryFields
      enabled
      gateEnabled
      gate {
        id
        type
        product {
          id
          name
        }
      }
      createdAt
      updatedAt
    }
  }
  ${CategoryFieldsFragmentDoc}
`

/**
 * __useCategoryQuery__
 *
 * To run a query within a React component, call `useCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoryQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCategoryQuery(baseOptions: Apollo.QueryHookOptions<CategoryQuery, CategoryQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CategoryQuery, CategoryQueryVariables>(CategoryDocument, options)
}
export function useCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CategoryQuery, CategoryQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CategoryQuery, CategoryQueryVariables>(CategoryDocument, options)
}
export type CategoryQueryHookResult = ReturnType<typeof useCategoryQuery>
export type CategoryLazyQueryHookResult = ReturnType<typeof useCategoryLazyQuery>
export type CategoryQueryResult = Apollo.QueryResult<CategoryQuery, CategoryQueryVariables>
export const CategoriesDocument = gql`
  query Categories($data: CategoryInput!) {
    categories(data: $data) {
      hasMore
      total
      list {
        ...categoryFields
      }
    }
  }
  ${CategoryFieldsFragmentDoc}
`

/**
 * __useCategoriesQuery__
 *
 * To run a query within a React component, call `useCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoriesQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCategoriesQuery(baseOptions: Apollo.QueryHookOptions<CategoriesQuery, CategoriesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CategoriesQuery, CategoriesQueryVariables>(CategoriesDocument, options)
}
export function useCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CategoriesQuery, CategoriesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CategoriesQuery, CategoriesQueryVariables>(CategoriesDocument, options)
}
export type CategoriesQueryHookResult = ReturnType<typeof useCategoriesQuery>
export type CategoriesLazyQueryHookResult = ReturnType<typeof useCategoriesLazyQuery>
export type CategoriesQueryResult = Apollo.QueryResult<CategoriesQuery, CategoriesQueryVariables>
export const CatalogDocument = gql`
  query Catalog($data: RecordByIdInput!) {
    catalog(data: $data) {
      ...catalogFields
      enabled
      gateEnabled
      createdAt
      updatedAt
      contentFilter {
        id
        operator
        rules {
          property
          operator
          categoryValue {
            id
            name
          }
        }
      }
    }
  }
  ${CatalogFieldsFragmentDoc}
`

/**
 * __useCatalogQuery__
 *
 * To run a query within a React component, call `useCatalogQuery` and pass it any options that fit your needs.
 * When your component renders, `useCatalogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCatalogQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCatalogQuery(baseOptions: Apollo.QueryHookOptions<CatalogQuery, CatalogQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CatalogQuery, CatalogQueryVariables>(CatalogDocument, options)
}
export function useCatalogLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CatalogQuery, CatalogQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CatalogQuery, CatalogQueryVariables>(CatalogDocument, options)
}
export type CatalogQueryHookResult = ReturnType<typeof useCatalogQuery>
export type CatalogLazyQueryHookResult = ReturnType<typeof useCatalogLazyQuery>
export type CatalogQueryResult = Apollo.QueryResult<CatalogQuery, CatalogQueryVariables>
export const CatalogsDocument = gql`
  query Catalogs($data: CatalogInput!) {
    catalogs(data: $data) {
      hasMore
      total
      list {
        ...catalogFields
      }
    }
  }
  ${CatalogFieldsFragmentDoc}
`

/**
 * __useCatalogsQuery__
 *
 * To run a query within a React component, call `useCatalogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCatalogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCatalogsQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCatalogsQuery(baseOptions: Apollo.QueryHookOptions<CatalogsQuery, CatalogsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CatalogsQuery, CatalogsQueryVariables>(CatalogsDocument, options)
}
export function useCatalogsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CatalogsQuery, CatalogsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CatalogsQuery, CatalogsQueryVariables>(CatalogsDocument, options)
}
export type CatalogsQueryHookResult = ReturnType<typeof useCatalogsQuery>
export type CatalogsLazyQueryHookResult = ReturnType<typeof useCatalogsLazyQuery>
export type CatalogsQueryResult = Apollo.QueryResult<CatalogsQuery, CatalogsQueryVariables>
export const PagesDocument = gql`
  query Pages($data: PageInput!) {
    pages(data: $data) {
      hasMore
      total
      list {
        id
        name
        description
        status
        publishOn
        expireOn
        gate {
          id
          type
          product {
            id
            name
          }
        }
      }
    }
  }
`

/**
 * __usePagesQuery__
 *
 * To run a query within a React component, call `usePagesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePagesQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function usePagesQuery(baseOptions: Apollo.QueryHookOptions<PagesQuery, PagesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PagesQuery, PagesQueryVariables>(PagesDocument, options)
}
export function usePagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PagesQuery, PagesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<PagesQuery, PagesQueryVariables>(PagesDocument, options)
}
export type PagesQueryHookResult = ReturnType<typeof usePagesQuery>
export type PagesLazyQueryHookResult = ReturnType<typeof usePagesLazyQuery>
export type PagesQueryResult = Apollo.QueryResult<PagesQuery, PagesQueryVariables>
export const RowDocument = gql`
  query Row($data: RecordByIdInput!) {
    row(data: $data) {
      ...rowFields
    }
  }
  ${RowFieldsFragmentDoc}
`

/**
 * __useRowQuery__
 *
 * To run a query within a React component, call `useRowQuery` and pass it any options that fit your needs.
 * When your component renders, `useRowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRowQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRowQuery(baseOptions: Apollo.QueryHookOptions<RowQuery, RowQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<RowQuery, RowQueryVariables>(RowDocument, options)
}
export function useRowLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RowQuery, RowQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<RowQuery, RowQueryVariables>(RowDocument, options)
}
export type RowQueryHookResult = ReturnType<typeof useRowQuery>
export type RowLazyQueryHookResult = ReturnType<typeof useRowLazyQuery>
export type RowQueryResult = Apollo.QueryResult<RowQuery, RowQueryVariables>
export const RowsDocument = gql`
  query Rows($data: RowInput!) {
    rows(data: $data) {
      hasMore
      total
      list {
        ...rowFields
      }
    }
  }
  ${RowFieldsFragmentDoc}
`

/**
 * __useRowsQuery__
 *
 * To run a query within a React component, call `useRowsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRowsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRowsQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRowsQuery(baseOptions: Apollo.QueryHookOptions<RowsQuery, RowsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<RowsQuery, RowsQueryVariables>(RowsDocument, options)
}
export function useRowsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RowsQuery, RowsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<RowsQuery, RowsQueryVariables>(RowsDocument, options)
}
export type RowsQueryHookResult = ReturnType<typeof useRowsQuery>
export type RowsLazyQueryHookResult = ReturnType<typeof useRowsLazyQuery>
export type RowsQueryResult = Apollo.QueryResult<RowsQuery, RowsQueryVariables>
export const ProductsDocument = gql`
  query Products($data: ProductInput!) {
    products(data: $data) {
      id
      name
      images {
        src
      }
      metadata {
        name
        value
      }
      object
      unitLabel
      url
      description
      active
      createdAt
      updatedAt
      livemode
      defaultPrice {
        id
        active
        currency
        createdAt
        updatedAt
        amount
        livemode
        product
        taxBehavior
        metadata {
          name
          value
        }
        object
        recurring {
          interval
          intervalCount
        }
        type
      }
      prices {
        id
        active
        currency
        createdAt
        updatedAt
        amount
        livemode
        product
        taxBehavior
        metadata {
          name
          value
        }
        object
        recurring {
          interval
          intervalCount
        }
        type
      }
    }
  }
`

/**
 * __useProductsQuery__
 *
 * To run a query within a React component, call `useProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useProductsQuery(baseOptions: Apollo.QueryHookOptions<ProductsQuery, ProductsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ProductsQuery, ProductsQueryVariables>(ProductsDocument, options)
}
export function useProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductsQuery, ProductsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ProductsQuery, ProductsQueryVariables>(ProductsDocument, options)
}
export type ProductsQueryHookResult = ReturnType<typeof useProductsQuery>
export type ProductsLazyQueryHookResult = ReturnType<typeof useProductsLazyQuery>
export type ProductsQueryResult = Apollo.QueryResult<ProductsQuery, ProductsQueryVariables>
export const StripeAccountLinkDocument = gql`
  query StripeAccountLink {
    stripeAccountLink {
      url
    }
  }
`

/**
 * __useStripeAccountLinkQuery__
 *
 * To run a query within a React component, call `useStripeAccountLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useStripeAccountLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStripeAccountLinkQuery({
 *   variables: {
 *   },
 * });
 */
export function useStripeAccountLinkQuery(
  baseOptions?: Apollo.QueryHookOptions<StripeAccountLinkQuery, StripeAccountLinkQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<StripeAccountLinkQuery, StripeAccountLinkQueryVariables>(StripeAccountLinkDocument, options)
}
export function useStripeAccountLinkLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<StripeAccountLinkQuery, StripeAccountLinkQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<StripeAccountLinkQuery, StripeAccountLinkQueryVariables>(
    StripeAccountLinkDocument,
    options,
  )
}
export type StripeAccountLinkQueryHookResult = ReturnType<typeof useStripeAccountLinkQuery>
export type StripeAccountLinkLazyQueryHookResult = ReturnType<typeof useStripeAccountLinkLazyQuery>
export type StripeAccountLinkQueryResult = Apollo.QueryResult<StripeAccountLinkQuery, StripeAccountLinkQueryVariables>
export const AwsCredentialsDocument = gql`
  query AWSCredentials($data: AWSCredentialsInput!) {
    awsCredentials(data: $data) {
      ...AWSCredentialFields
    }
  }
  ${AwsCredentialFieldsFragmentDoc}
`

/**
 * __useAwsCredentialsQuery__
 *
 * To run a query within a React component, call `useAwsCredentialsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAwsCredentialsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAwsCredentialsQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAwsCredentialsQuery(
  baseOptions: Apollo.QueryHookOptions<AwsCredentialsQuery, AwsCredentialsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AwsCredentialsQuery, AwsCredentialsQueryVariables>(AwsCredentialsDocument, options)
}
export function useAwsCredentialsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AwsCredentialsQuery, AwsCredentialsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AwsCredentialsQuery, AwsCredentialsQueryVariables>(AwsCredentialsDocument, options)
}
export type AwsCredentialsQueryHookResult = ReturnType<typeof useAwsCredentialsQuery>
export type AwsCredentialsLazyQueryHookResult = ReturnType<typeof useAwsCredentialsLazyQuery>
export type AwsCredentialsQueryResult = Apollo.QueryResult<AwsCredentialsQuery, AwsCredentialsQueryVariables>
export const TenantDocument = gql`
  query Tenant {
    tenant {
      id
      name
    }
  }
`

/**
 * __useTenantQuery__
 *
 * To run a query within a React component, call `useTenantQuery` and pass it any options that fit your needs.
 * When your component renders, `useTenantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTenantQuery({
 *   variables: {
 *   },
 * });
 */
export function useTenantQuery(baseOptions?: Apollo.QueryHookOptions<TenantQuery, TenantQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<TenantQuery, TenantQueryVariables>(TenantDocument, options)
}
export function useTenantLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TenantQuery, TenantQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<TenantQuery, TenantQueryVariables>(TenantDocument, options)
}
export type TenantQueryHookResult = ReturnType<typeof useTenantQuery>
export type TenantLazyQueryHookResult = ReturnType<typeof useTenantLazyQuery>
export type TenantQueryResult = Apollo.QueryResult<TenantQuery, TenantQueryVariables>
export const MeDocument = gql`
  query Me {
    me {
      id
      email
      firstName
      lastName
    }
  }
`

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options)
}
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options)
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>
