/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, {
  forwardRef,
  useMemo,
  useRef,
  useEffect,
  useState,
  useImperativeHandle,
  HTMLProps,
  ForwardedRef,
} from 'react'
import { Table, Pagination, Select, Checkbox, Alert } from '../../ui'
import TableRowSkeleton from '../loaders/TableRowSkeleton'
import Loading from '../Loading'
import {
  selectTable,
  setPageIndex,
  setPageSize,
  setSelection,
  selectRow,
  TableRow,
} from '../../../store/data/tableSlice'
import { useDispatch, useSelector } from 'react-redux'
import { DragAndDrop } from './components'
import { MdDragIndicator } from 'react-icons/md'
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
  SortingState,
  ColumnDef,
  Row,
  CellContext,
} from '@tanstack/react-table'
import classNames from 'classnames'
import { DropResult } from 'react-beautiful-dnd'

// useState, useImperativeHandle
// Alert
// react-table

const { Tr, Th, Td, THead, TBody, Sorter } = Table

type IntermediateCheckboxProps = unknown & {
  indeterminate?: boolean
  onCheckBoxChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onIndeterminateCheckBoxChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  checked?: boolean
  disabled?: boolean
}

const IndeterminateCheckbox = forwardRef(function IntermediateCheckbox(props: IntermediateCheckboxProps, ref) {
  const { indeterminate, onChange, onCheckBoxChange, onIndeterminateCheckBoxChange, ...rest } = props

  const defaultRef = useRef()
  const resolvedRef = ref || defaultRef

  useEffect(() => {
    // @ts-ignore
    resolvedRef.current.indeterminate = indeterminate
  }, [resolvedRef, indeterminate])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange?.(e)
    onCheckBoxChange?.(e)
    onIndeterminateCheckBoxChange?.(e)
  }

  // @ts-ignore
  return <Checkbox className="mb-0" ref={resolvedRef} onChange={(_, e) => handleChange(e)} {...rest} />
})

export type DataTableColumn<T extends object> = ColumnDef<T> & {
  sortable?: boolean
  dragHandleProps?: HTMLProps<HTMLSpanElement>
}

export type CellProps<TData, TValue = unknown> = CellContext<TData, TValue> & {
  dragHandleProps?: HTMLProps<HTMLSpanElement>
}

type DataTableProps<T extends object> = {
  skeletonAvatarColumns?: number[]
  columns: DataTableColumn<T>[]
  data?: T[]
  total?: number
  loading?: boolean
  onCheckBoxChange?: (checked: boolean, row: T) => void
  onIndeterminateCheckBoxChange?: (checked: boolean, rows: T[]) => void
  onPaginationChange?: (pageIndex: number) => void
  onSelectChange?: (pageSize: number) => void
  onSort?: (sort: { order: string; key: string }, options?: { id: string; clearSortBy: () => void }) => void
  pageSizes?: number[]
  selectable?: boolean
  skeletonAvatarProps?: unknown
  autoResetSelectedRows?: boolean
  name: string
  draggable?: boolean
  sortable?: boolean
  onDragEnd?: (result: DropResult & { newData: T[] }) => void
  pagination?: boolean
}

const DataTable = forwardRef(function DataTable<T extends TableRow<unknown>>(
  props: DataTableProps<T>,
  ref: ForwardedRef<unknown>,
) {
  const {
    skeletonAvatarColumns,
    columns: columnsProp,
    data: incomingData,
    total: incomingTotal,
    loading = false,
    onCheckBoxChange,
    onIndeterminateCheckBoxChange,
    onPaginationChange,
    onSelectChange,
    onSort,
    pageSizes = [10, 25, 50, 100],
    selectable = false,
    skeletonAvatarProps,
    draggable = false,
    sortable = false,
    name,
    onDragEnd,
    pagination = true,
  } = props

  const first = useRef(true)
  const cache = useRef<{ data: T[]; total?: number }>({ data: [] })

  if (incomingData) {
    cache.current = { data: incomingData, total: incomingTotal }
    first.current = false
  }

  const { data, total } = useMemo(() => cache.current, [cache.current])

  const [sorting, setSorting] = useState<SortingState>([])

  const dispatch = useDispatch()

  const {
    currentPage: { size: pageSize, index },
  } = useSelector(selectTable(name))

  const pageIndex = index + 1

  const pageSizeOption = useMemo(
    () => pageSizes?.map((number) => ({ value: number, label: `${number} / page` })),
    [pageSizes],
  )

  const handleCheckBoxChange = (checked: boolean, row: T) => {
    if (!loading) {
      dispatch(selectRow({ key: name, row, selected: checked }))
      onCheckBoxChange?.(checked, row)
    }
  }

  const handleIndeterminateCheckBoxChange = (checked: boolean, selectedRows: Row<T>[]) => {
    if (!loading) {
      const rows = selectedRows.map((row) => row.original)
      dispatch(setSelection({ key: name, selection: checked ? rows : [] }))
      onIndeterminateCheckBoxChange?.(checked, rows)
    }
  }

  useEffect(() => {
    if (Array.isArray(sorting)) {
      const sortOrder = sorting.length > 0 ? (sorting[0]?.desc ? 'desc' : 'asc') : ''
      const id = sorting.length > 0 && sorting[0]?.id ? sorting[0].id : ''
      onSort?.({ order: sortOrder, key: id })
    }
  }, [sorting])

  const hasOldColumnMetaKey = columnsProp.some(
    (col: DataTableColumn<T> & { Header?: unknown; accessor?: unknown; Cell?: unknown }) =>
      col.Header || col.accessor || col.Cell,
  )

  const finalColumns = useMemo(() => {
    let columns: DataTableColumn<T>[] = columnsProp

    if (draggable) {
      columns = [
        {
          id: 'dragger',
          header: '',
          enableSorting: false,
          accessorKey: 'dragger',
          cell: ({ dragHandleProps }: CellProps<T, unknown>) => {
            return (
              <span {...dragHandleProps}>
                <MdDragIndicator />
              </span>
            )
          },
        },
        ...columns,
      ]
    }
    if (selectable) {
      columns = [
        {
          id: 'select',
          header: ({ table }) => (
            <IndeterminateCheckbox
              checked={table.getIsAllRowsSelected()}
              indeterminate={table.getIsSomeRowsSelected()}
              onChange={table.getToggleAllRowsSelectedHandler()}
              onIndeterminateCheckBoxChange={(e) => {
                handleIndeterminateCheckBoxChange(e.target.checked, table.getRowModel().rows)
              }}
            />
          ),
          cell: ({ row }) => (
            <IndeterminateCheckbox
              checked={row.getIsSelected()}
              disabled={!row.getCanSelect()}
              indeterminate={row.getIsSomeSelected()}
              onChange={row.getToggleSelectedHandler()}
              onCheckBoxChange={(e) => handleCheckBoxChange(e.target.checked, row.original)}
            />
          ),
        },
        ...columns,
      ]
    }
    return columns
  }, [columnsProp, selectable, draggable])

  const table = useReactTable({
    data,
    columns: hasOldColumnMetaKey ? [] : finalColumns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualPagination: true,
    manualSorting: sortable,
    onSortingChange: setSorting,
    state: sortable
      ? {
          sorting,
        }
      : undefined,
  })

  const resetSorting = () => {
    table.resetSorting()
  }

  const resetSelected = () => {
    table.toggleAllRowsSelected(false)
  }

  useImperativeHandle(ref, () => ({
    resetSorting,
    resetSelected,
  }))

  if (hasOldColumnMetaKey) {
    const message =
      'You are using old react-table v7 column config, please use v8 column config instead, refer to our demo or https://tanstack.com/table/v8'

    if (process.env.NODE_ENV === 'development') {
      console.warn(message)
    }

    return <Alert>{message}</Alert>
  }

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const handlePaginationChange = (page: number | undefined) => {
    onPaginationChange?.(Number(page))
    dispatch(setPageIndex({ index: Number(page) - 1, key: name }))
  }

  const handleSelectChange = (value: number | undefined) => {
    if (!loading) {
      onSelectChange?.(Number(value))
      dispatch(setPageSize({ size: Number(value), key: name }))
    }
  }

  return (
    // @ts-ignore
    <Loading
      loading={!first.current && loading && (!incomingData || incomingData?.length === 0) && data.length !== 0}
      type="cover">
      {/* @ts-ignore */}
      <Table className={'w-full'}>
        {/* @ts-ignore */}
        <THead>
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <Th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <div
                        className={classNames(
                          header.column.getCanSort() && 'cursor-pointer select-none point',
                          loading && 'pointer-events-none',
                        )}
                        onClick={header.column.getToggleSortingHandler()}>
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {header.column.getCanSort() && sortable && <Sorter sort={header.column.getIsSorted()} />}
                      </div>
                    )}
                  </Th>
                )
              })}
            </Tr>
          ))}
        </THead>
        {loading && first.current ? (
          <TableRowSkeleton
            columns={finalColumns.length}
            rows={pageSize}
            avatarInColumns={skeletonAvatarColumns}
            // @ts-ignore
            avatarProps={skeletonAvatarProps}
          />
        ) : draggable ? (
          <DragAndDrop table={table} data={data} onDragEnd={onDragEnd} />
        ) : (
          // @ts-ignore
          <TBody>
            {table
              .getRowModel()
              .rows.slice(0, 10)
              .map((row) => {
                return (
                  <Tr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return <Td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Td>
                    })}
                  </Tr>
                )
              })}
          </TBody>
        )}
      </Table>
      {pagination ? (
        <div className="flex items-center justify-between mt-4">
          <Pagination pageSize={pageSize} currentPage={pageIndex} total={total} onChange={handlePaginationChange} />
          <div style={{ minWidth: 130 }}>
            <Select
              size="sm"
              menuPlacement="top"
              isSearchable={false}
              value={
                pageSizeOption?.filter((option) => option.value === pageSize)?.[0] as
                  | { value: number; label: string }
                  | undefined
              }
              options={pageSizeOption}
              onChange={(option) => handleSelectChange((option as { value: number; label: string } | undefined)?.value)}
            />
          </div>
        </div>
      ) : null}
    </Loading>
  )
})

export default DataTable
