import React, { ForwardedRef, HTMLProps } from 'react'
import classNames from 'classnames'

type TrProps<HTMLPropsT = HTMLTableRowElement> = HTMLProps<HTMLPropsT> & {
  asElement?: React.ElementType
  children?: React.ReactNode
}

const Tr = React.forwardRef(function Tr<HTMLPropsT, HTMLElementT = HTMLTableRowElement>(
  props: TrProps<HTMLPropsT>,
  ref: ForwardedRef<HTMLElementT>,
) {
  const { children, asElement: Component = 'tr', className, ...rest } = props

  const trClass = classNames(Component !== 'tr' && 'tr', className)

  return (
    <Component className={trClass} ref={ref} {...rest}>
      {children}
    </Component>
  )
})

export default Tr
