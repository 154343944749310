import React from 'react'
import { Action, AdaptableCard, DataTable } from '../../../components/shared'
import { Row } from '../../../graphql'
import { CellProps } from './DataTable'
import { DataTableColumn } from './DataTable'
import { ActionCell, NameCell } from './components/cells'
import { TableTools } from './components'
import { Maybe } from 'yup/es/types'
import { DropResult } from 'react-beautiful-dnd'

export type DefaultColumns = {
  title: DataTableColumn<Row>
  description: DataTableColumn<Row>
  catalog: DataTableColumn<Row>
  actions: DataTableColumn<Row>
}

type RowTableProps = {
  data?: {
    total: number
    list?: Array<Maybe<Row>>
    hasMore: boolean
  }
  name: string
  loading?: boolean
  columns?: ((entryColumns: DefaultColumns) => DataTableColumn<Row>[]) | DataTableColumn<Row>[]
  onSelectChange?: (pageSize: number) => void
  onPaginationChange?: (pageIndex: number) => void
  onSearchInputChange?: (value: string) => void
  draggable?: boolean
  sortable?: boolean
  onDragEnd?: (result: DropResult & { newData: Row[] }) => void
  actions?: Action[]
  onEdit?: (row: Row) => void
  onDelete?: (row: Row) => void
}

function RowTable({
  data: { total, list } = { total: 0, hasMore: false },
  loading = false,
  columns: incomingColumns = (c) => Object.keys(c).map((k) => c[k as keyof DefaultColumns]),
  name: tableName,
  onSearchInputChange,
  onEdit,
  onDelete,
  ...rest
}: RowTableProps) {
  const defaultColumns: DefaultColumns = {
    title: {
      header: 'Title',
      accessorKey: 'title',
      sortable: false,
      cell: ({
        row: {
          original: { title },
        },
      }: CellProps<Row>) => {
        return <NameCell name={title} />
      },
    },
    description: {
      header: 'Description',
      accessorKey: 'description',
      sortable: false,
      cell: ({
        row: {
          original: { catalog },
        },
      }: CellProps<Row>) => {
        return catalog?.description
      },
    },
    catalog: {
      header: 'Catalog',
      accessorKey: 'catalog',
      sortable: false,
      // eslint-disable-next-line @typescript-eslint/no-shadow
      cell: ({
        row: {
          original: { catalog },
        },
      }: CellProps<Row>) => {
        return catalog?.name
      },
    },
    actions: {
      header: '',
      accessorKey: 'actions',
      sortable: false,
      cell: ({ row }: CellProps<Row>) => <ActionCell row={row.original} onEdit={onEdit} onDelete={onDelete} />,
    },
  }

  let columns: DataTableColumn<Row>[] = []
  if (typeof incomingColumns === 'function') {
    columns = incomingColumns(defaultColumns)
  } else {
    columns = incomingColumns
  }

  return (
    <AdaptableCard className="h-full" bodyClass="h-full">
      <TableTools tableName={tableName} onSearchInputChange={onSearchInputChange} search />
      <DataTable
        name={tableName}
        columns={columns as DataTableColumn<Row>[]}
        data={list as Row[]}
        loading={loading}
        total={total}
        skeletonAvatarProps={{ width: 40, height: 40 }}
        skeletonAvatarColumns={[0]}
        {...rest}
      />
    </AdaptableCard>
  )
}

export default RowTable
