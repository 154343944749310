import React from 'react'
import { Card, CardField } from '../ui'
import { Catalog, Category, EntryStatus, VideoFieldsFragment } from '../../graphql'
import dayjs from 'dayjs'
import { DATE_TIME_FORMAT } from '../../constants/theme.constant'

type StatusCardProps = {
  entry?: VideoFieldsFragment | Category | Catalog
}

const statuses = [
  { label: 'Published', value: EntryStatus.Published, color: 'bg-green-500' },
  { label: 'Unpublished', value: EntryStatus.Unpublished, color: 'bg-orange-500' },
  { label: 'Expired', value: EntryStatus.Expired, color: 'bg-red-500' },
  { label: 'Disabled', value: EntryStatus.Disabled, color: 'bg-gray-500' },
]

const StatusCard = ({ entry }: StatusCardProps) => {
  if (!entry) return null
  const currentStatus = statuses.find((s) => s.value === entry.status)
  return (
    <Card>
      <div className="flex flex-col mx-auto">
        <div className="grid grid-cols-1 gap-y-4 gap-x-4">
          {currentStatus && <CardField value={currentStatus.label} color={currentStatus.color} />}
          <CardField title={'Created At'} value={dayjs(entry.createdAt).format(DATE_TIME_FORMAT)} />
          <CardField title={'Edited At'} value={dayjs(entry.updatedAt).format(DATE_TIME_FORMAT)} />
        </div>
      </div>
    </Card>
  )
}

export default StatusCard
