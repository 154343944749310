import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import useAuth from './useAuth'

function useAutoSignOut() {
  const keepSignedIn = useSelector<{ auth: { session: { keepSignedIn: boolean } } }>((state) => {
    return state.auth.session.keepSignedIn
  })

  const { signOut } = useAuth()

  useEffect(() => {
    const beforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault()
      if (!keepSignedIn) {
        signOut()
      }
    }
    window.addEventListener('beforeunload', beforeUnload)
    return () => {
      window.removeEventListener('beforeunload', beforeUnload)
    }
  })
}

export default useAutoSignOut
