import React from 'react'
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd'
import { Table, flexRender } from '@tanstack/react-table'
import { Table as TableComponent } from '../../../ui'

const { TBody, Tr, Td } = TableComponent

type DragAndDropProps<T extends object> = {
  table: Table<T>
  data: T[]
  onDragEnd?: (result: DropResult & { newData: T[] }) => void
}

function DragAndDrop<T extends object>({ table, data, onDragEnd }: DragAndDropProps<T>) {
  const reorderData = (startIndex: number, endIndex: number) => {
    const newData: (T & { order?: number })[] = data.map((row) => ({ ...row }))
    const [movedRow] = newData.splice(startIndex, 1)
    newData.splice(endIndex, 0, movedRow as T)
    newData.forEach((row, index) => {
      row.order = index + 1
    })
    return newData
  }

  const handleDragEnd = (result: DropResult) => {
    const { source, destination } = result
    if (!destination) return
    const newData = reorderData(source.index, destination.index)
    onDragEnd?.({ ...result, newData })
  }

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="table-body">
        {(provided) => (
          <TBody ref={provided.innerRef} {...provided.droppableProps}>
            {table.getRowModel().rows.map((row) => {
              return (
                <Draggable draggableId={row.id.toString()} key={row.id} index={row.index}>
                  {(p, snapshot) => {
                    const { style } = p.draggableProps
                    return (
                      <Tr
                        ref={p.innerRef}
                        className={snapshot.isDragging ? 'table' : ''}
                        style={{ ...style, cursor: 'grab' }}
                        {...p.draggableProps}
                        {...p.dragHandleProps}
                        role={undefined}>
                        {row.getVisibleCells().map((cell) => {
                          return <Td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Td>
                        })}
                      </Tr>
                    )
                  }}
                </Draggable>
              )
            })}
            {provided.placeholder}
          </TBody>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default DragAndDrop
