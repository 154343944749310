import React from 'react'
import TableFilters from './TableFilters'
import { Button } from '../../../ui'
import { Filters } from '../../Filter'
import { clearFilters, TableRow, TableFilters as TFilters } from '../../../../store/data/tableSlice'
import { useDispatch, useSelector } from 'react-redux'
import TableSearch from './TableSearch'
import { selectTable, TableFilters as TableFiltersT } from '../../../../store/data/tableSlice'

type TableToolsProps<F extends TableFiltersT, FilterAdditional> = {
  tableName: string
  filters?: Filters<F, FilterAdditional>
  search?: boolean
  onSearchInputChange?: (value: string) => void
}

function TableTools<Row extends TableRow, F extends TFilters, FilterAdditional>({
  tableName,
  filters,
  search = false,
  onSearchInputChange,
}: TableToolsProps<F, FilterAdditional>) {
  const dispatch = useDispatch()

  let tableSearch: HTMLInputElement | null = null

  const handleClearAll = () => {
    if (tableSearch) {
      tableSearch.value = ''
    }
    dispatch(clearFilters(tableName))
  }

  const handleSearchInputChange = (value: string) => {
    onSearchInputChange?.(value)
  }

  const { search: s, filters: f } = useSelector(selectTable<Row, F>(tableName))

  let showClear = false

  if (filters) {
    Object.keys(filters).forEach((key) => {
      const value = f[key]
      if (Array.isArray(value)) {
        if (value.length) {
          showClear = true
        }
      } else if (value && (value as unknown as string) !== '') {
        showClear = true
      }
    })
  }

  if (s && s !== '') {
    showClear = true
  }

  let clearAll = null

  if (showClear) {
    clearAll = (
      <div className="mb-4">
        <Button size="sm" onClick={handleClearAll}>
          Clear All
        </Button>
      </div>
    )
  }

  return (
    <div className="md:flex flex-wrap items-center gap-x-4 justify-between">
      <div className="md:flex flex-wrap items-center gap-x-4">
        {search && (
          <TableSearch ref={(r) => (tableSearch = r)} tableName={tableName} onInputChange={handleSearchInputChange} />
        )}
        <TableFilters tableName={tableName} filters={filters} />
      </div>
      {clearAll}
    </div>
  )
}

export default TableTools
