import React from 'react'
import { Select, SelectProps } from '../../../../components/ui'

function DefaultFilter<Option, Additional, FormValues>({
  size = 'sm',
  className = 'mb-4 min-w-[130px]',
  ...props
}: SelectProps<Option, Additional, FormValues>) {
  //todo: add support for single value color filters, for now hardcoded as isMulti
  return <Select size={size} className={className} {...props} />
}

export default DefaultFilter
